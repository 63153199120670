<template>
    <div class="mobile-basket">
      <div v-if="itemcount > 0" >
        <b-row class="totalsrow">
          <b-col class="col-12 row totalsrow">
            <b-col cols="6">
              <h2 class="itemcount">{{itemcounttext}}</h2>
            </b-col>
            <b-col offset="2" cols="4" class="st-price-col"><button class="col-12 btn-link reset-link" @click="resetOrder()">Reset</button></b-col>
          </b-col>
        </b-row>
        <b-row class="totals-row">
          <b-col class="col-12 row delivery-charge" v-if="order.type === 3">
            <b-col cols="6">
              Delivery Charge
            </b-col>
            <b-col offset="2" cols="4" class="st-price-col">{{venue.delivery_fee}}</b-col>
          </b-col>
          <b-col class="col-12 row mt-2 total-charge">
            <b-col cols="6">
              Total Charge
            </b-col>
            <b-col offset="2" cols="4" class="tot-price-col">£{{calculateSubtotal.toFixed(2)}}</b-col>
          </b-col>
        </b-row>
        <div class="row w-100">
          <div class="button-wrapper col-4">
            <b-button class="view-order-button" @click="viewOrder">View {{thingtoview}}</b-button>
          </div>
          <div class="button-wrapper col-8" v-if="order.type != null">
            <b-button class="place-order-button" :disabled="!canPlaceOrder" @click="checkout()" v-if="venueIsOpen">Place Your Order</b-button>
            <b-button class="place-order-button" disabled v-else>Venue Not Open</b-button>
          </div>
          <div class="button-wrapper col-8" v-else>
            <b-button class="place-order-button"  v-if="venueIsOpen" @click="forceServiceChange()">Select Service Type</b-button>
            <b-button class="place-order-button" disabled v-else>Venue Not Open</b-button>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <img src="@/assets/table_service.svg" class="offset-1 col-5 stop-being-massive-in-safari">
        <div class="col-5 gimmepadding" >
          <h4>Your Order is empty</h4>
        </div>
        <div class="button-wrapper col-4">
          <b-button class="view-order-button" @click="viewOrder">View {{thingtoview}}</b-button>
        </div>
        <div class="button-wrapper col-8">
          <b-button class="place-order-button" disabled>Place Your Order</b-button>
        </div>
      </div>

<!--        <div class="order-panel-container">-->
<!--            <b-row class="top-heading">-->
<!--                <b-col cols="4">-->
<!--                    <h4>Your Order</h4>-->
<!--                </b-col>-->
<!--                <b-col offset="5" cols="2" v-if="buttons === true">-->
<!--                    <button class="btn-link reset-link" @click="resetOrder()">Reset</button>-->
<!--                </b-col>-->
<!--            </b-row>-->
<!--            <b-row class="product-rows">-->
<!--                <b-col class="col-12 row" v-for="itm in order.items" :key="itm.id">-->
<!--                    <b-col class="btn-col" cols="3">-->
<!--                        <b-btn class="sub-btn inc-btn" @click="changeItmQuantity(itm,-1)">-</b-btn>-->
<!--                        {{itm.quantity}}-->
<!--                        <b-btn class="plus-btn inc-btn" @click="changeItmQuantity(itm,1)">+</b-btn>-->
<!--                    </b-col>-->
<!--                    <b-col cols="5" class="name-col">{{itm.name}}</b-col>-->
<!--                    <b-col cols="4" class="price-col"  v-if="itm.combined_products && itm.combined_products.length > 0">£{{((itm.pricePerItem * itm.quantity) + (itm.combined_products[0].price * itm.quantity)).toFixed(2)}}</b-col>-->
<!--                    <b-col cols="4" class="price-col"  v-else>£{{(itm.pricePerItem * itm.quantity).toFixed(2)}}</b-col>-->
<!--                    <b-col cols="12" class="row" v-if="itm.combined_products && itm.combined_products.length > 0">-->
<!--                      <b-col offset="3" cols="8" class="combined-products-col">+ {{itm.combined_products[0].name }}&nbsp; (£{{itm.combined_products[0].price.toFixed(2)}})&nbsp;&nbsp;&nbsp;  <b-btn class="sub-btn inc-btn" @click="removeCombinedProduct(itm)">X</b-btn></b-col>-->
<!--                    </b-col>-->
<!--                    <b-col offset="1" cols="11" class="fullwidthline"></b-col>-->
<!--                </b-col>-->
<!--            </b-row>-->
<!--            <b-row class="totals-row">-->
<!--                <b-col class="col-12 row " v-if="order.type === 3">-->
<!--                    <b-col cols="6">-->
<!--                        Delivery Charge-->
<!--                    </b-col>-->
<!--                    <b-col offset="2" cols="4" class="st-price-col">{{venue.delivery_fee}}</b-col>-->
<!--                </b-col>-->
<!--                <b-col class="col-12 row mt-2">-->
<!--                    <b-col cols="6">-->
<!--                        Subtotal-->
<!--                    </b-col>-->
<!--                    <b-col offset="2" cols="4" class="tot-price-col">£{{calculateSubtotal.toFixed(2)}}</b-col>-->
<!--                </b-col>-->
<!--            </b-row>-->
<!--            <b-row class="button-row">-->
<!--              <b-col class="col-12 row">-->
<!--                <b-col cols="12" v-if="order.type != null">-->
<!--                  <b-button class="order-button" @click="checkout()" v-if="buttons === true">Place {{deliveryType}} order</b-button>-->
<!--                </b-col>-->
<!--                <b-col cols="12" v-else>-->
<!--                  <b-button class="order-button" @click="forceServiceChange()" v-if="buttons === true">Select Service Type</b-button>-->
<!--                </b-col>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--        </div>-->
    </div>
</template>

<script>
    import axios from "@/main";

    export default {
        name: "LgMobileOrderPanel",
        props:[
            'order', 'venue', 'buttons'
        ],
        data() {
            return {
              venueHasTimeSlotsForCollection: false,
              venueHasTimeSlotsForDelivery: false,
            }
        },
        mounted() {

        },
        computed:{
          canPlaceOrder()
          {
             if(this.order.type != null && this.itemcount > 0)
             {
               return true;
             }
             else return false;
          },
          venueIsOpen()
          {
            if(this.order.type != null && this.itemcount > 0)
            {
              return true;
            }
            else return false;
          },
          thingtoview(){
            if(this.$route.fullPath.includes('basket') && (! this.$route.fullPath.includes('v/')))
            {
              return 'Menu'
            }
            else return 'Order'
          },
          itemcount(){
            if(this.order && this.order.items){
              return this.order.items.length;
            }
            else return 0;
          },
          itemcounttext(){
            if(this.order && this.order.items){
              if(this.order.items.length === 1){
                return '1 item';
              }
              else return this.order.items.length + ' items';
            }
            else return 0;

          },
            deliveryType(){
                switch(this.order.type)
                {
                    case 0:
                        return 'click & collect';
                    case 3:
                        return 'delivery';
                    default:
                        return null;
                }
            },
            calculateSubtotal()
            {
                var orderSubtotal = 0;
                var reduce = this.order.items.reduce(function (acc, item) {
                  var tot = (item.pricePerItem * item.quantity);
                  if(item.combined_products && item.combined_products.length > 0)
                  {
                    tot = tot + (item.combined_products[0].price * item.quantity);
                  }
                  return acc + tot;
                },0)
                orderSubtotal = orderSubtotal + reduce;
                if(this.order.type === 3)//delivery
                {
                    orderSubtotal = orderSubtotal + this.venue.delivery_fee_double;
                }
                return orderSubtotal;
            }
          },
        watch:{
            venue: function (newVenue,oldVenue){
              if(oldVenue === null && newVenue != null)
              {
                this.loadVenueTimeslots(newVenue.slug);
              }
            }
        },
        methods: {
          loadVenueTimeslots(slug){
            let self = this;
            axios.get('api/customer/venue/'+slug+'/delivery-slots').then(function (response) {
              self.venueHasTimeSlotsForDelivery = response.data.length > 0;
            });
            axios.get('api/customer/venue/'+slug+'/collection-slots').then(function (response) {
              self.venueHasTimeSlotsForCollection = response.data.length > 0;
            });
          },
            removeCombinedProduct(itm){
              itm.combined_products = [];
            },
            checkout(){
                this.$store.commit('setFullOrder',this.order);
              this.$store.commit('setVenue',this.venue.slug);
                this.$router.push('/checkout');
            },
          viewOrder(){
            if(this.$route.fullPath.includes('basket') && (! this.$route.fullPath.includes('v/')))
            {
              this.$store.commit('setFullOrder',this.order);
              this.$store.commit('setVenue',this.venue.slug);
              this.$router.push('/v/'+this.venue.slug);
            }
            else{
              this.$store.commit('setFullOrder',this.order);
              this.$store.commit('setVenue',this.venue.slug);
              this.$router.push('/mobile-basket');
            }

          },
            forceServiceChange()
            {
               this.$emit('showServiceModal')
            },
            resetOrder()
            {
                this.order.items = [];
                this.order.venue = null;
                this.order.type = null;
                this.delivery_postcode = null;
                this.order.venue = this.venue.slug;
                this.$store.commit('clearOrder');
                this.$store.commit('setVenue',this.venue.slug);
            },
            changeItmQuantity(itm,quantity)
            {
                if(quantity < 0 && itm.quantity <= 1)
                {
                    this.order.items.splice(this.order.items.findIndex(function(i){
                        return i.id === itm.id;
                    }), 1);
                }
                else{
                    itm.quantity = itm.quantity + quantity;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
  h4{
    font-weight: 600;
    font-size: 15px;
    color: #070314;
  }
  p{
    font-weight: 400;
    font-size: 12px;
  }
    .inc-btn{
      border-radius: 100%;
      max-width: 15px;
      width: 15px;
      height: 15px;
      padding: 0;
      max-height: 15px;
      font-size: 14px;
      padding-bottom: 1px;
      line-height: 7px;
      margin-bottom: 3px;
      background-color: #A3AFC0;
      border-color: #A3AFC0;
    }
    .sub-btn{
      margin-right: 4px;
    }
    .plus-btn{
      margin-left: 4px;
    }

    .order-panel-container{

      .top-heading{
         padding-bottom: 40px;
      }
    }
    .name-col{
      text-align: left !important;
    }
    .price-col{
      font-weight: 700;
      color: #070314;
      text-align: right !important;
    }
    .combined-products-col{
      font-size: 14px;
      color: #070314;
      text-align: left !important;
      padding-left: 23px;
    }
    .st-price-col{
      font-weight: 700;
      text-align: right !important;
      color: #A3AFC0;
    }
    .tot-price-col{
      font-weight: 700;
      color: #070314;
      font-size: 20px;
      text-align: right !important;
    }
    .fullwidthline{
      border-bottom: 1px solid #A3AFC0;
      margin-bottom: 12px;
    }
    .fullwidthline{
      margin-left: 22px;
      margin-top: 6px;
      color: #A3AFC0;
      opacity: 30%;
    }
    .btn-col{
      font-weight: 700;
    }
    .totals-row{
      margin-top: 10px;
      text-align: left !important;
    }
    .order-button{
      width: 100%;
      border-radius: 8px;
      background: linear-gradient(270deg,#71C1FF,#6F47FF);
      border: none;
      height: 40px;
      margin-top: 10px;
    }
    .place-order-button{
      width: 94%;
      border-radius: 8px;
      background: linear-gradient(270deg,#71C1FF,#6F47FF);
      border: none;
      margin-top: 10px;
      height: 40px;
      font-weight: 600;
      margin-left: 5px;
      margin-right: 5px;
    }
    .view-order-button{
      width: 100%;
      height: 40px;
      border-radius: 8px;
      background: white;
      color: black;
      border: solid 1px #CBD7E8;
      margin-top: 10px;
      margin-left: 5px;
      font-weight: 600;
      margin-right: 5px;
    }
    .mobile-basket{
      min-height:  160px;
      box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      background: white;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 11px;
      padding-bottom: 35px;
      margin-left: 0 !important;
      margin-right:0 !important;
    }
    .gimmepadding{
      padding-top: 20px;
    }
    //.button-wrapper{
    //  padding-right: 8px !important;
    //  padding-left: 8px !important;
    //}
    .reset-link{
      font-size: 14px;
      padding-right: 0 !important;
      text-align: right;
      font-weight: 700;
    }
    @media(max-width: 440px){
      .view-order-button{
        font-size: 12px;
      }
      .place-order-button{
        font-size: 12px;
      }
    }
    .itemcount{
      font-size: 20px;
      font-weight: 600;
      color: #070314;
    }
    .delivery-charge{
      color: #A3AFC0;
      font-size: 14px;
    }
    .total-charge{
      font-size: 14px;
    }
    .totalsrow{
      width: 100vw;
      text-align: left;
    }
    .stop-being-massive-in-safari{
      max-height: 90px !important;
    }
</style>