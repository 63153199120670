<template>
  <b-row>
    <!-- Request Cancellation -->
    <b-col cols="12" class="mt-2">
      <b-button
          variant="secondary"
          size="sm"

          class="w-100"

          @click="modalIsOpen = true"

          v-if="order.booking != null && order.booking.can_cancel"
      >
        Request Cancellation
      </b-button>
      <!-- v-model used so we can watch and make axios request for fees -->
      <b-modal id="cancelOrderModal" title="Request Cancellation" v-model="modalIsOpen">
        <p class="my-4">{{order.booking.venue.cancellation_policy}}</p>

        <div class="loader" v-if="!feesLoaded"></div>

        <b-row class="cancellation-details" v-else>
          <b-col cols="12">
            <h4>Details</h4>
          </b-col>

          <b-col cols="12" class="fees-row">
            <div class="d-flex justify-content-between fees-content">
              <div class="title">Amount</div>
              <div class="value">{{toCurrency(fees.total)}}</div>
            </div>
          </b-col>
          <b-col cols="12" class="fees-row">
            <div class="d-flex justify-content-between fees-content">
              <div class="title">Fees (non refundable)</div>
              <div class="value">{{toCurrency(fees.fees)}}</div>
            </div>
          </b-col>
          <b-col cols="12" class="fees-row refund-amount">
            <div class="d-flex justify-content-between fees-content">
              <div class="title">Refund Amount</div>
              <div class="value">{{toCurrency(fees.refund_amount)}}</div>
            </div>
          </b-col>
        </b-row>

        <template #modal-footer>
          <div class="w-100">
            <b-button
                variant="gradient"

                class="w-100"

                @click="cancelOrder"

                :disabled="isCancelling"
            >
              {{isCancelling ? 'Loading...' : 'Cancel Booking'}}
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import axios from "@/main";
export default {
  name: "LgOrderCancellation",

  props: {
    order: {
      required: true,
      type: Object
    }
  },

  data(){
    return {
      modalIsOpen: false,

      fees: {},
      feesLoaded: false,

      isCancelling: false
    }
  },

  beforeMount(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.accessToken}`;
  },

  methods: {
    toCurrency(value){
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      }).format(value / 100);
    },

    cancelOrder(){
      this.isCancelling = true;

      axios.post(`/api/customer/bookings/${this.order.booking.id}/cancel`)
          .then(() => {
            this.$toastr.s('SUCCESS', 'Booking Cancelled Successfully');

            this.modalIsOpen = false;
            this.isCancelling = false;

            this.$emit('order-cancelled');
          })
         .catch(() => {
           this.$toastr.e('ERROR', 'Booking Failed To Cancel');

           this.isCancelling = false;
         })
    }
  },

  watch: {
    modalIsOpen(value){
      if(value && !this.feesLoaded){
        axios.get(`/api/customer/bookings/${this.order.booking.id}/check-fees`)
            .then((response) => {
              this.feesLoaded = true;
              this.fees = response.data;
            })
            .catch((error) => {
              console.error(error);

              this.$toastr.e('ERROR', 'Order fees could not be retrieved');
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-gradient {
    background: linear-gradient(270deg,#71C1FF,#6F47FF);
    color: #ffffff;
    border-radius: 8px;
    border: 0;
    padding: 10px 16px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }

  .loader {
    color: #6F47FF;
  }

  .fees-row {
    > .fees-content {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    &:not(:last-child) > .fees-content{
      border-bottom: 1px solid #A3AFC0;
    }

    .title {
      font-family: Barlow, Roboto, Open Sans, sans-serif;
      color: #2c3e50;
    }

    .value {
      font-weight: bold;
    }

    &.refund-amount {
      .title, .value {
        font-size: 1.2rem;
      }

      .value {

      }
    }
  }
</style>
