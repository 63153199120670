<template>
    <div id="whole-page">

      <div class="d-md-none mobile-greyscale position-absolute" v-show="showAppAdvert"></div>
        <b-container fluid="md" class="inner-container" id="header-container">
            <!--    {{ $route.params.venueslug }}-->
            <div class="venue-container"  v-if="isPageLoaded">
                <b-row>
                    <b-col cols="12" md="5" order-md="2">
                        <agile>
                            <div class="slide" v-for="(item,key) in gallery.items" :key="key" :class="`slide--${key}`">
                                <img class="agile__image" :src="item.src" width="100%">
                            </div>
                        </agile>
                    </b-col>
                    <b-col cols="12" md="7" class="greyline">
                        <div class="venue-information">
                            <h1>{{venue.name}}</h1>
                            <h4>{{venue.subtitle}}</h4>
                            <p>{{venue.description}}</p>
                            <p><a :href="venueMapLocation">View on map</a></p>
                        </div>
                        <div class="venue-offers d-inline-block float-left">
                            <div class="venue-offer-item d-flex" v-if="venue.collection_orders">
                                <img src="@/assets/bag.svg"/><span class="offer-item-text  align-self-center">Click and collect</span>
                            </div>
                            <div class="venue-offer-item d-flex" v-if="venue.delivery_orders">
                                <img src="@/assets/menu_active.svg"/><span class="offer-item-text   align-self-center">Delivery within {{venue.delivery_radius}} miles</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" md="6">
                        <div class="venue-information open-times-exception">
                            <h4>Open Times</h4>
                            <table>
                                <tbody>
                                <tr v-for="item in venue.opening_times" :key="item.day">
                                    <td class="venue-open-times">{{item.day}}</td>
                                    <td class="venue-open-times">{{computeOpenings(item)}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="pageNotLoaded" v-else>
                Loading...
            </div>
        </b-container>
        <b-container  v-if="menu_is_food !== null" fluid class="mid-page-nav">
            <b-container class="middle-container" fluid="md">
                <b-row class="m-c-row-height">
                    <b-col class="label-padding col-6 col-md-4 col-xl-2">
                        <div class="toggle" :class="menu_is_food ? 'toggle-food' : 'toggle-drinks'">
                            <div class="toggle-highlight"></div>
                            <div class="toggle-inner">
                                <label class="toggle-text toggle-drinks-text mb-0" for="drink">
                                    Drinks
                                    <input class="d-none" type="radio" name="radio" :value="false" v-model="menu_is_food" id="drink"/>
                                </label>
                                <label class="toggle-text toggle-food-text mb-0" for="food">
                                    Food
                                    <input class="d-none" type="radio" name="radio" :value="true" checked="checked" v-model="menu_is_food" id="food"/>
                                </label>
                            </div>
                        </div>
                    </b-col>
                    <b-col md="8" xl="10" class="food-button-wrapper row d-none d-md-flex" v-if="menu_is_food === true && this.food_menu.categories">
                        <div class="col"  v-for="cat in parsedFoodMenuCategories" :key="cat.id">
                            <b-button @click="showMenuCategory(cat)" class="menu-button" :class="activeMenuCategory === cat ? 'active-menu-category' : null">{{cat.name}}</b-button>
                        </div>
                        <div class="col" v-if="moreFoodMenuCategories.length > 0">
                            <b-dropdown class="menu-dropdown text-white" text="More"  variant='info'>
                                <b-dropdown-item @click="showMenuCategory(item)" class="menu-dropdown-item" v-for="item in moreFoodMenuCategories" :key="item.id">{{item.name}}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </b-col>
                    <b-col md="8" xl="10" class="food-button-wrapper row d-none d-md-flex" v-if="menu_is_food === false && this.drink_menu.categories">
                        <div class="col"  v-for="cat in parsedDrinkMenuCategories" :key="cat.id">
                            <b-button @click="showMenuCategory(cat)"  class="menu-button" :class="activeMenuCategory === cat ? 'active-menu-category' : null">{{cat.name}}</b-button>
                        </div>
                        <div class="col" v-if="moreDrinkMenuCategories.length > 0">
                            <b-dropdown class="menu-dropdown" text="More" variant='info'>
                                <b-dropdown-item @click="showMenuCategory(item)" class="menu-dropdown-item"  v-for="item in moreDrinkMenuCategories" :key="item.id">{{item.name}}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </b-col>
                  <b-col  class="col-5 food-button-wrapper d-md-none" v-if="menu_is_food === true && this.food_menu.categories">
                    <b-dropdown class="menu-dropdown" :text="activeMenuCategoryLabel" variant='info'>
                      <b-dropdown-item @click="showMenuCategory(item)" class="menu-dropdown-item" v-for="item in food_menu.categories" :key="item.id">{{item.name}}</b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col  class="col-5 food-button-wrapper d-md-none" v-if="menu_is_food === false && this.drink_menu.categories">
                    <b-dropdown class="menu-dropdown" :text="activeMenuCategoryLabel" variant='info'>
                      <b-dropdown-item @click="showMenuCategory(item)" class="menu-dropdown-item" v-for="item in drink_menu.categories" :key="item.id">{{item.name}}</b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container  v-if="isPageLoaded" fluid="md" class="bottom-section-container">
            <b-row>
                <b-col lg="6" v-if="this.order.type === null && this.isViewingMenu === false">
                  <lg-order-type-selection :dt="venueHasTimeSlotsForDelivery" :ct="venueHasTimeSlotsForCollection" :venue="venue" :order="order" :ismodal="false" v-on:viewMenu="isViewingMenu = true"></lg-order-type-selection>
                </b-col>
                <b-col lg="6" v-else>
                    <div class="order-type-selection" v-if="activeMenuCategory == null">
                        <img src="@/assets/start_order.svg">
                        <h4>Now please select a food or drink menu to see our range of products available for {{deliveryType}}</h4>

                    </div>
                    <div  class="order-type-selection"  v-else>
                        <lg-menu-panel :category="activeMenuCategory" v-on:product-selected="setProductSelected($event)"></lg-menu-panel>
                    </div>
                </b-col>
                <b-col lg="5" offset-lg="1" class="d-none d-md-block">
                    <div class="order-panel">
                        <div  v-if="this.order.items.length > 0">
                            <lg-order-panel :order="order" :venue="venue" :buttons="true" :fees="0" v-on:showServiceModal="changeService()"></lg-order-panel>
                        </div>
                        <div class="order-panel-container" v-else>
                            <img src="@/assets/table_service.svg">
                            <h4>Your Order is empty</h4>
                            <p>All your menu items you place on the app will show here ready to order</p>
                            <b-button disabled>Place Your Order</b-button>
                        </div>
                    </div>
                    <b-button class="view-menu" @click="changeService()">Change Service Type</b-button>
                </b-col>
            </b-row>
          <lg-mobile-order-panel  :order="order" :venue="venue" :buttons="true" v-on:showServiceModal="changeService()" class="fixed-bottom d-md-none row w-100"></lg-mobile-order-panel>
          <div class="mobile-get-the-app-popup d-md-none row fixed-bottom justify-content-center align-items-center w-100" v-show="showAppAdvert">
            <div class="row gtap-row justify-content-center align-items-center">
              <h3 class="get-the-app-title col-12">
                Continue using Local Global in...
              </h3>
              <div class="col-12 row hori-row">
                <img class="col-2 asset-logo" src="@/assets/app_icon.svg"/>
                <div class="col-4 app-text">The App</div>
                <b-btn class="offset-3 col-3 gtap-app-btn" @click="redirectToApp()">Open</b-btn>
              </div>
              <div class="col-12 row hori-row">
                <img class="col-2 asset-logo" :src="calcBrowserIcon"/>
                <div class="col-4 app-text">Browser</div>
                <b-btn class="offset-3 col-3 gtap-browser-btn" @click="hidePopUp()">Continue</b-btn>
              </div>
            </div>
          </div>
        </b-container>
        <b-modal id="deliveryRadiusModal" title="Enter your Delivery Postcode">
            <h4>In order to check we can deliver to you, please type your postcode below</h4>
            <form v-if="delivery_postcode == null || canDeliverToCurrentPostcode === false">
                <div class="form-group row mb-2">
                    <div class="offset-md-2 col-md-8">
                        <label for="delivery_postcode" class="col-md-12 pl-0 col-form-label text-md">Postcode</label>
                        <input id="delivery_postcode" type="text" class="form-control"  name="delivery_postcode" v-model="delivery_postcode" required autocomplete="postcode" autofocus>
                    </div>
                </div>
            </form>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                            variant="primary"
                            size="sm"
                            class="float-right"
                            @click="checkPostcodeRadius()"
                    >
                        Check Postcode
                    </b-button>
                </div>
            </template>
        </b-modal>
        <b-modal id="changeService" :title="changeServiceTitle">
          <lg-order-type-selection :ismodal="true"  v-on:hideOrderTypeModal="hideOrderType()"  :dt="venueHasTimeSlotsForDelivery" :ct="venueHasTimeSlotsForCollection" :venue="venue" :order="order" v-on:viewMenu="isViewingMenu = true; hideOrderType()"></lg-order-type-selection>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                            variant="dark"
                            size="sm"
                            class="float-right"
                            @click="$bvModal.hide('changeService')"
                    >
                        Close
                    </b-button>
                </div>
            </template>
        </b-modal>
        <b-modal id="menuModal" title="Add to order">
            <b-row v-if="productSelected != null">
                <b-col cols="3">{{productSelected.name}}</b-col>
                <b-col offset="6" cols="3">£{{productSelected.price.toFixed(2)}}</b-col>
            </b-row>
          <b-row v-if="activeMenuCategory && activeMenuCategory.related_products && activeMenuCategory.related_products.length > 0">
            <label for="combined_product" class="col-12 col-form-label text-md">Combined Product</label>
            <select id="combined_product" class="offset-1 col-10 form-control" name="combined_product" v-model="combinedProduct" required>
              <option :value="null">Choose Option</option>
              <option v-for="relprod in activeMenuCategory.related_products" :key="relprod.id" v-bind:value="relprod">{{relprod.name}} &nbsp; &nbsp; +£{{relprod.price.toFixed(2)}}</option>
            </select>
          </b-row>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                            variant="dark"
                            size="sm"
                            @click="$bvModal.hide('menuModal')"
                    >
                        Cancel
                    </b-button>
                    <b-button
                            variant="primary"
                            size="sm"
                            class="float-right"
                            @click="addProduct()"
                    >
                        Add to Order
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>

</template>

<script>
import axios from "../main";
import LgMenuPanel from "../components/LgMenuPanel";
import LgOrderPanel from "../components/LgOrderPanel";
import LgOrderTypeSelection from "@/components/LgOrderTypeSelection";
import LgMobileOrderPanel from "@/components/LgMobileOrderPanel";
export default {
  components:{
    LgMobileOrderPanel,
      LgMenuPanel,
    LgOrderTypeSelection,
      LgOrderPanel
  },
  name: 'Venue',
  data() {
    return {
        isViewingMenu: false,//toggle for if they can view menu without selecting delivery
        productSelected: null,
        combinedProduct: null,
        delivery_postcode: null,
        canDeliverToCurrentPostcode: false,
        venueHasTimeSlotsForDelivery: false,
        venueHasTimeSlotsForCollection: false,
        venue: null,
        order: {
            type: null,
            items: [],
            venue: null
        },
        food_menu: {},
        drink_menu: null,
        isPageLoaded: false,
        menu_is_food: null,
        gallery: {
            width: NaN,
            height: NaN,
            items: [
            ]
        },
        currentId: null,
        activeMenuCategory: null,
        showAppAdvert: true,
        pageHasLoaded: false
    }
  },

  async mounted() {
    // let err = this.$store.getters.globalError;
    // if(err != null)
    // {
    //   this.$toastr.e("ERROR", err);
    // }
    if(this.$cookies.get('mobile-device-preference') === 'browser')
    {
      this.showAppAdvert = false;
    }
    let self = this;
    await axios.get('api/customer/venue/'+self.$route.params.venueslug).then(function (response2) {
        self.venue = response2.data;
        self.isPageLoaded = true;
        self.gallery.items = response2.data.images.map(item => {
            const wrapper = {};
            wrapper.src = item;
            wrapper.thumbnail = item;
            return wrapper;
        })
        self.loadMenu();
        self.loadVenueTimeslots();
    }).catch(function (err) {
      try{
        console.log(err);
        if(err.response.status === 404)
        {
          self.$router.push('/404');
        }
        else{
          console.log(err.response);
        }
      }
      catch(err2){
        console.log(err2)
      }
    });
    this.menu_is_food = true;
    // console.log(this.$store.getters.order);
    this.order = this.$store.getters.order;

    if(this.$route.query.delivery_postcode != null)
    {
      this.delivery_postcode = this.$route.query.delivery_postcode;
    }
    else{
      this.delivery_postcode = this.$store.getters.deliveryPostcode;
    }

    if(this.order.venue !== this.venue.slug)
    {
      this.order.items = [];
      this.order.venue = null;
      this.order.type = null;
      this.delivery_postcode = null;
      this.$store.commit('clearOrder');
      this.$store.commit('setVenue',this.venue.slug);
    }
    // console.log('about to log order');
    // console.log(this.$store.getters.order);
    //   console.log('order logged');
    this.pageHasLoaded = true;
  },
  computed: {
    activeMenuCategoryLabel()
    {
      if(this.activeMenuCategory != null)
      {
        return this.activeMenuCategory.name;
      }
      else{
        return "Menu";
      }
    },
    calcBrowserIcon()
    {
      //if safari
      if(navigator.vendor ===  "Apple Computer, Inc.")
      {
        return require('../assets/safari.svg');
      }
      else return require('../assets/chrome.svg');
    },
      changeServiceTitle()
      {
        if(this.order.type !== null)
        {
          return 'Change your order method';
        }
        else{
          return 'Select Service Type';
        }
      },
      venueMapLocation(){
          return 'https://www.google.com/maps/search/?api=1&query=' + this.venue.address.coordinates.latitude + ',' + this.venue.address.coordinates.longitude + '&query_place_id=' + this.venue.address.query_place_id;
      },
      parsedDrinkMenuCategories(){
          //tbc
          if(this.drink_menu.categories)
          {
              return this.drink_menu.categories.slice(0,5);
          }
          else{
              return {};
          }
      },
      moreDrinkMenuCategories(){
          if(this.drink_menu.categories)
          {
              return this.drink_menu.categories.slice(5);
          }
          else{
              return {};
          }
      },
      moreFoodMenuCategories(){
          //tbc
          if(this.food_menu.categories)
          {
              return this.food_menu.categories.slice(5);
          }
          else{
              return {};
          }
      },
      parsedFoodMenuCategories(){
          //tbc
          if(this.food_menu.categories)
          {
              return this.food_menu.categories.slice(0,5);
          }
          else{
              return {};
          }
      },
      deliveryType(){
          switch(this.order.type)
          {
              case 0:
                  return 'click & collect';
              case 3:
                  return 'delivery';
              default:
                  return '';
          }
      },
  },
  methods: {
      hideOrderType(){
        this.$bvModal.hide('changeService');
      },
      changeService(){
          this.$bvModal.show('changeService');
      },
      checkPostcodeRadius(){
          if(this.delivery_postcode != null)
          {
            if(this.delivery_postcode.length < 5)
            {
              this.$toastr.e("ERROR", "Postcode must have more than 5 characters");
            }
            else{
              let self = this;
              let config3 = {
                headers: {
                  'Authorization': 'Bearer ' + self.$store.getters.accessToken,
                }
              }
              axios.post('api/customer/venue/'+self.$route.params.venueslug+'/geocode',{
                'order_type': 'delivery',
                'postcode': self.delivery_postcode
              },config3).then(function (response2) {
                if(response2.data.in_radius === true)
                {
                  self.canDeliverToCurrentPostcode = true;
                  self.$bvModal.hide('deliveryRadiusModal')
                  self.$toastr.s("SUCCESS", "You can now continue with your order");
                  self.$store.commit('deliveryPostcode',self.delivery_postcode);
                }
                else{
                  self.canDeliverToCurrentPostcode = false;
                  self.$toastr.e("ERROR", "The entered postcode was not in the radius. Please try a new postcode or change venue");
                  self.delivery_postcode = null;
                }
              }).catch(function () {
                self.canDeliverToCurrentPostcode = false;
                self.$toastr.e("ERROR", "The entered postcode could not be found");
                self.delivery_postcode = null;
              });
            }

          }
          else{
              return false;
          }
      },

      addProduct()
      {
        let self = this;
        let filter = self.order.items.filter(x => x.id === self.productSelected.id)
        if(filter.length > 0)
        {
          //then we have this product already in our array...
          let assigned = false;
          filter.forEach(function (item){
            if(assigned === false)
            {
              if(self.combinedProduct != null)
              {
                if(item.combined_products && item.combined_products.length > 0)
                {
                //then the one we are looking at has a combined product

                  //if we do as well, and it is the same
                  if(item.combined_products[0].id === self.combinedProduct.id)
                  {
                    //then we are on the right combined product
                    item.quantity = item.quantity + 1;
                    assigned = true;
                  }
                }
              }
              else{
                //we don't have a combined product
                //so if item doesn't either, we can assign...
                if(item.combined_products === null)
                {
                  item.quantity = item.quantity + 1;
                  assigned = true;
                }
              }
            }
          });
          if(assigned === true)
          {
            //then we already assigned it, so we don't need to...
            self.$bvModal.hide('menuModal')
            self.$toastr.s(this.productSelected.name + ' was just added to the order.', "Product Added");
            self.productSelected = null;
            return true; //don't run the rest of the function...
          }
        }
        this.order.items.push({
            id: this.productSelected.id,
            quantity: 1,
            name: this.productSelected.name,
            pricePerItem: this.productSelected.price,
            combined_products: this.calculateCombinedProducts()
        })

        this.$bvModal.hide('menuModal')
        this.$toastr.s(this.productSelected.name + ' was just added to the order.', "Product Added");
        this.productSelected = null;
      },
      calculateCombinedProducts()
      {
        if(this.combinedProduct === null){
          return null;
        }
        else{
          return [
            {
                id: this.combinedProduct.id,
                quantity: 1,
                price: this.combinedProduct.price,
                name: this.combinedProduct.name
            }
          ]
        }
      },
      setProductSelected(product){
          this.productSelected = product;
      },
      showMenuCategory(cat)
      {
          //needs to be the whole object not just the one
          this.activeMenuCategory = cat;
      },
      setOrderType(type)
      {
          if(this.venue.slug !== this.$store.getters.orderVenue)
          {
              this.$store.commit('clearOrder');
              this.$store.commit('setVenue',this.venue.slug);
          }
          //set or update the delivery type
          this.order.type = type;
          this.$store.commit('setOrderType',type);
          this.$toastr.s("SUCCESS", "Order type changed to " + this.deliveryType);
          this.$bvModal.hide('orderType');
      },

      computeOpenings(item)
      {
          if(item.openings.length > 0)
          {
              var str = "";
              item.openings.forEach(function (item2) {
                  str = str + " " + item2.open + " - " + item2.close + ',';
              });
              return str.substr(0,str.length-1)
          }
          else{
              return "Closed"
          }

      },
      loadVenueTimeslots(){
        let self = this;
        axios.get('api/customer/venue/'+self.$route.params.venueslug+'/delivery-slots').then(function (response) {
          self.venueHasTimeSlotsForDelivery = response.data.length > 0;
        });
        axios.get('api/customer/venue/'+self.$route.params.venueslug+'/collection-slots').then(function (response) {
          self.venueHasTimeSlotsForCollection = response.data.length > 0;
        });
      },
      loadMenu(){


      },
      hidePopUp(){
        this.$cookies.set('mobile-device-preference','browser',60*60*24*21)//21 days
        this.showAppAdvert = false;
      },
      redirectToApp()
      {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // alert(userAgent);
          if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
            window.location.replace('https://apps.apple.com/gb/app/id1521447907')
          }
          else{
            window.location.replace('https://play.google.com/store/apps/details?id=com.imageplus.localglobal');
          }
      }
  },
  watch: {
      menu_is_food(){
          let self = this;
          axios.get('api/customer/venue/'+self.$route.params.venueslug+'/menu').then(function (response2) {
              // console.log(response2.data);
            self.food_menu = response2.data.find(o => o.name === "Food");
            self.drink_menu = response2.data.find(o => o.name === "Drink");
              //FOOD AND DRINK MENU GOES HERE set the two variables and v-if between them
          }).catch(function (err) {
              if(err.response.status === 404)
              {
                  self.$router.push('/404');
              }
              else{
                  // console.log(err.response);
              }
          });
      },
      'order.type'(){
          if(this.order.type === 3 && this.delivery_postcode === null && this.pageHasLoaded === true)
          {
              this.$bvModal.show('deliveryRadiusModal')
          }

      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

 .venue-information{
   text-align: left;
   margin-top: 15px;
   margin-bottom: 15px;
   h1{
     padding-top: 4px;
     font-size: 34px;
     color: #070314;
     font-weight: 600;
       margin-bottom: 1px;
   };
  h4{
   color: #070314;
   font-size: 14px;
   font-weight: 700;
  };
   p{
     color: #070314;
     font-size: 14px;
     font-weight: 400;
     a{
         color: #7196FF;
         &:hover{
             color: darken(#7196FF,20%);
             cursor: pointer;
         }
     }
   }
 }
 .offer-item-text{
     font-size: 15px;
     font-weight: 600;
 }

 .greyline{
     border-bottom: solid #A3AFC0 1px;
 }
 .venue-open-times{
     font-size: 14px;
     font-weight: 400;
 }
 .mid-page-nav{
     width: 100%;
     padding-left: 0;
     padding-right: 0;
     margin-left: 0;
     margin-right: 0;
     background-color: #ffffff;
     height: 67px;
 }
    .bottom-section-container{
        min-height: 50px;
    }


    .menu-button{
        /*max-width: 90px;*/
        background-color: transparent;
        color: #7290FF;
        font-weight: bold;
        border: none;
    }
    .menu-button:active{
         background-color: #7290FF !important;
         font-weight: bold;
         color: white;
         border-radius: 16px;
    }
    .active-menu-category{
        background-color: #7290FF;
        font-weight: bold;
        color: white;
        border-radius: 16px;
    }

    .food-button-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }



     .view-menu{
         background: none;
         border: none;
         color: #7290FF;
         font-size: 14px;
         font-weight: 700;
     }
     .view-menu:hover{
         color: darken(#7196FF,20%);
     }
 .view-menu:active{
     color: darken(#7196FF,20%) !important;
     background: none !important;
     border: none !important;
 }
 .view-menu:focus{
     color: darken(#7196FF,20%) !important;
     background: none !important;
     border: none !important;
     box-shadow: none !important;
 }
     .sub-btn{
         padding: 2px;
         font-size: 14px;
     }
     .plus-btn{
         padding: 2px;
         font-size: 14px;
 }
    .is-active-lob{
        background-color: #7196FF !important;
        color: white !important;
    }
    .can-be-selected{
        margin-left: 30px !important;
        margin-right: 30px !important;
    }
    .venue-offer-item{
      margin-top: 15px;
      img{
        margin-right: 15px;
      }
    }

    .toggle {
        border-radius: 16px;
        width: 136px;
        height: 37px;
        background-color: #F5F9FF;
        padding: 3px;
        position: relative;
        display: flex;
        align-items: center;
    }

    .toggle-highlight {
        background-color: white;
        box-shadow: 0px 1px 2px #A3AFC0;
        border-radius: 16px;
        width: 67px;
        height: 30px;
        position: absolute;
        top: 3px;
        left: 3px;
    }

    .toggle-inner {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100px;
        margin: auto;
      label{
        padding-left: 0;
        padding-right: 0;
      }
    }

    .toggle-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #A3AFC0;
        cursor: pointer;
        transition: color 0.3s;
        user-select: none;
    }

    .toggle-highlight {
        transition: transform 0.3s;
        transform: translateX(0);
    }

    .toggle-drinks {
        .toggle-drinks-text {
            color: #7290FF;
        }
    }

    .toggle-food {
        .toggle-food-text {
            color: #7290FF;
        }

        .toggle-highlight {
            transform: translateX(67px);
        }
    }
    .label-padding{
      padding-top: 15px;
    }
  .m-c-row-height{
    height: 67px;
  }

  @media(max-width: 768px)
  {
    .open-times-exception{
      margin-top: 15px !important;
    }
    .label-padding{
      margin-left: 10px;
    }
    .venue-offers{
      padding-bottom: 20px;
      margin-left: 8px;
    }
    .venue-information{
      margin-left: 10px;
      margin-top: 0;
      h1{
        font-size: 20px;
      }
      margin-right: 10px;
    }
    .agile{
      padding-left: 10px;
      padding-right: 10px;
    }

    .order-type-selection{
      padding-left: 0;
      padding-right: 0;
      padding-top: 40px;
      padding-bottom: 10px;
      margin-bottom: 100px;
    }

    #whole-page{
      padding-bottom: 160px;
    }
    .food-button-wrapper{
      justify-content: left;
    }
  }
 .mobile-get-the-app-popup{
   z-index: 10302;
   background-color: white !important;
   margin-left: 0 !important;
   margin-right: 0 !important;
   height: 33vh;
   border-top-left-radius: 16px;
   border-top-right-radius: 16px;
   touch-action: none;
 }
 .get-the-app-title{
   font-weight: 600;
   font-size: 20px;
   color: #070314;
   margin-bottom: 30px;
 }
 .mobile-greyscale{
   background: black;
   opacity: 0.4;
   height: 100vh;
   touch-action: none;
   width: 100%;
   z-index: 10301;
 }
 .gtap-row{
   margin-left: 10px;
   margin-right: 10px;
 }
 .asset-logo{
   height: 42px;
   padding-left: 0 !important;
   padding-right: 0 !important;
   width: 42px;
 }
 .app-text{
   font-weight: 700;
   font-size: 14px;
   vertical-align: top;
   padding-top: 10px
 }
 .hori-row{
   margin-top: 20px;
 }
 .gtap-browser-btn{
   width: 100%;
   border-radius: 8px;
   background: white;
   color: black;
   border: solid 1px #CBD7E8;
 }
 .gtap-app-btn{
   width: 100%;
   border-radius: 8px;
   background: linear-gradient(270deg,#71C1FF,#6F47FF);
   border: none;
 }
 .menu-dropdown {
   width: 150px;
   border-radius: 16px;
   background: #7290FF;
 }

</style>
