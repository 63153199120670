<template>
    <div>
        <div class="order-panel-container">
            <b-row class="top-heading">
                <b-col md="4" cols="6">
                    <h4>Your Order</h4>
                </b-col>
                <b-col offset="5" cols="2" v-if="buttons === true">
                    <button class="btn-link reset-link" @click="resetOrder()">Reset</button>
                </b-col>
            </b-row>
            <b-row class="product-rows">
                <b-col class="col-12 row" v-for="itm in order.items" :key="itm.id">
                    <b-col class="btn-col" cols="5" xl="3">
                        <b-btn class="sub-btn inc-btn" @click="changeItmQuantity(itm,-1)">-</b-btn>
                        {{itm.quantity}}
                        <b-btn class="plus-btn inc-btn" @click="changeItmQuantity(itm,1)">+</b-btn>
                    </b-col>
                    <b-col cols="4" xl="5" class="name-col">{{itm.name}}</b-col>
                    <b-col cols="3" xl="4" class="price-col"  v-if="itm.combined_products && itm.combined_products.length > 0">£{{((itm.pricePerItem * itm.quantity) + (itm.combined_products[0].price * itm.quantity)).toFixed(2)}}</b-col>
                    <b-col cols="3" xl="4" class="price-col"  v-else>£{{(itm.pricePerItem * itm.quantity).toFixed(2)}}</b-col>
                    <b-col cols="12" class="row" v-if="itm.combined_products && itm.combined_products.length > 0">
                      <b-col offset="3" cols="8" class="combined-products-col">+ {{itm.combined_products[0].name }}&nbsp; (£{{itm.combined_products[0].price.toFixed(2)}})&nbsp;&nbsp;&nbsp;  <b-btn class="sub-btn inc-btn" @click="removeCombinedProduct(itm)">X</b-btn></b-col>
                    </b-col>
                    <b-col offset="1" cols="11" class="fullwidthline"></b-col>
                </b-col>
            </b-row>
            <b-row class="totals-row">
                <b-col class="col-12 row " v-if="order.type === 3">
                    <b-col cols="6">
                        Delivery Charge
                    </b-col>
                    <b-col offset="2" cols="4" class="st-price-col">{{venue.delivery_fee}}</b-col>
                </b-col>
              <b-col class="col-12 row " v-if="fees > 0">
                <b-col cols="6">
                  Fees
                </b-col>
                <b-col offset="2" cols="4" class="st-price-col">£{{fees.toFixed(2)}}</b-col>
              </b-col>
                <b-col class="col-12 row mt-2">
                    <b-col cols="6">
                        Subtotal
                    </b-col>
                    <b-col offset="2" cols="4" class="tot-price-col">£{{calculateSubtotal.toFixed(2)}}</b-col>
                </b-col>
            </b-row>
            <b-row class="button-row">
              <b-col class="col-12 row">
                <b-col cols="12" v-if="order.type != null">
                  <b-button class="order-button" @click="checkout()" v-if="buttons === true">Place {{deliveryType}} order</b-button>
                </b-col>
                <b-col cols="12" v-else>
                  <b-button class="order-button" @click="forceServiceChange()" v-if="buttons === true">Select Service Type</b-button>
                </b-col>
              </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LgOrderPanel",
        props:[
            'order', 'venue', 'buttons', 'fees'
        ],
        data() {
            return {

            }
        },
        mounted() {
            // console.log('about to log menucat');
            // console.log(this.category);
            // console.log('about to log menucat');
        },
        computed:{
            deliveryType(){
                switch(this.order.type)
                {
                    case 0:
                        return 'click & collect';
                    case 3:
                        return 'delivery';
                    default:
                        return null;
                }
            },
            calculateSubtotal()
            {
                var orderSubtotal = 0;
                var reduce = this.order.items.reduce(function (acc, item) {
                  var tot = (item.pricePerItem * item.quantity);
                  if(item.combined_products && item.combined_products.length > 0)
                  {
                    tot = tot + (item.combined_products[0].price * item.quantity);
                  }
                  return acc + tot;
                },0)
                orderSubtotal = orderSubtotal + reduce;
                if(this.order.type === 3)//delivery
                {
                    orderSubtotal = orderSubtotal + this.venue.delivery_fee_double;
                }
                orderSubtotal = orderSubtotal + this.fees;
                return orderSubtotal;
            }
        },
        methods: {
            removeCombinedProduct(itm){
              itm.combined_products = [];
            },
            checkout(){
                this.$store.commit('setFullOrder',this.order);
              this.$store.commit('setVenue',this.venue.slug);
                this.$router.push('/checkout');
            },
            forceServiceChange()
            {
               this.$emit('showServiceModal')
            },
            resetOrder()
            {
                this.order.items = [];
                this.order.venue = null;
                this.order.type = null;
                this.delivery_postcode = null;
                this.order.venue = this.venue.slug;
                this.$store.commit('clearOrder');
                this.$store.commit('setVenue',this.venue.slug);
            },
            changeItmQuantity(itm,quantity)
            {
                if(quantity < 0 && itm.quantity <= 1)
                {
                    this.order.items.splice(this.order.items.findIndex(function(i){
                        return i.id === itm.id;
                    }), 1);
                }
                else{
                    itm.quantity = itm.quantity + quantity;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .inc-btn{
      border-radius: 100%;
      max-width: 15px;
      width: 15px;
      height: 15px;
      padding: 0;
      max-height: 15px;
      font-size: 14px;
      padding-bottom: 1px;
      line-height: 7px;
      margin-bottom: 3px;
      background-color: #A3AFC0;
      border-color: #A3AFC0;
    }
    .sub-btn{
      margin-right: 4px;
    }
    .plus-btn{
      margin-left: 4px;
    }

    .order-panel-container{
       h4{
         font-weight: 600;
         font-size: 20px;
         color: #070314;
       }
      .top-heading{
         padding-bottom: 40px;
      }
    }
    .reset-link{
      margin-right: 40px;
    }
    .name-col{
      text-align: left !important;
    }
    .price-col{
      font-weight: 700;
      color: #070314;
      text-align: right !important;
    }
    .combined-products-col{
      font-size: 14px;
      color: #070314;
      text-align: left !important;
      padding-left: 23px;
    }
    .st-price-col{
      font-weight: 700;
      text-align: right !important;
      color: #070314;
    }
    .tot-price-col{
      font-weight: 700;
      color: #070314;
      font-size: 20px;
      text-align: right !important;
    }
    .fullwidthline{
      border-bottom: 1px solid #A3AFC0;
      margin-bottom: 12px;
      margin-left: 22px;
      margin-top: 6px;
      color: #A3AFC0;
      opacity: 30%;
    }
    .btn-col{
      font-weight: 700;
    }
    .totals-row{
      margin-top: 25px;
      text-align: left !important;
    }
    .order-button{
      width: 100%;
      border-radius: 8px;
      background: linear-gradient(270deg,#71C1FF,#6F47FF);
      border: none;
      margin-top: 25px;
    }

</style>