<template>
    <div>
        <div class="menu-panel-wrapper">
            <div  v-if="category.products.length > 0">
              <div v-for="item in category.products" :key="item.id" @click="callFunc(item)">
                <b-row class="menu-item" v-if="item.image">
                  <b-col cols="6" md="7" class="text-left">
                    <h4>{{item.name}}</h4>
                    <p class="description">{{defaultDescription(item.description)}}</p>
                    <h3 class="price" :class="item.description == null ? 'price-no-desc' : ''">£{{item.price.toFixed(2)}}</h3>
                  </b-col>
                  <b-col offset-md="1" cols="6" md="4">
                    <img class="product-img" :src="item.image"/>
                  </b-col>
                </b-row>
                <b-row class="menu-item" v-else>
                  <b-col cols="12" class="text-left">
                    <h4>{{item.name}}</h4>
                    <p class="description">{{defaultDescription(item.description)}}</p>
                    <h3 class="price" :class="item.description == null ? 'price-no-desc' : ''">£{{item.price.toFixed(2)}}</h3>
                  </b-col>
                </b-row>
              </div>
<!--                <b-row class="menu-item" >-->
<!--                    <b-col cols="6" md="7" class="text-left">-->
<!--                        <h4>{{item.name}}</h4>-->
<!--                        <p class="description">{{defaultDescription(item.description)}}</p>-->
<!--                        <h3 class="price" :class="item.description == null ? 'price-no-desc' : ''">£{{item.price.toFixed(2)}}</h3>-->
<!--                    </b-col>-->
<!--                    <b-col offset-md="1" cols="6" md="4">-->
<!--                        <img class="product-img" :src="item.image"/>-->
<!--                    </b-col>-->
<!--                </b-row>-->
            </div>
            <div class="no-products-available" v-else>
                <img src="@/assets/start_order.svg">
                <h4>There are no products available in this menu category. Please select a different one! </h4>
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        name: "LgMenuPanel",
        props:[
            'category'
        ],
        data() {
            return {
                productSelected: null,
                'menucat' : {}
            }
        },
        mounted() {
            // console.log('about to log menucat');
            // console.log(this.category);
            // console.log('about to log menucat');
        },
        methods: {
            callFunc(item)
            {
                this.productSelected = item;
                this.$emit('product-selected', item)
                this.$bvModal.show('menuModal');
            },
            defaultDescription(description)
            {
               if(description != null && description.length > 0)
               {
                 return description;
               }
               else{
                 return ""
               }
            },
            addProduct()
            {

            },

        }
    }
</script>

<style scoped lang="scss">
    .order-type-selection{
        padding: 5px;
        text-align: left;
        h4{
            margin:10px;
            color: #070314;
            font-weight: 400;
            font-size: 14px;
        }
        h3{
          margin:10px;
          color: #070314;
          font-weight: 700;
          font-size: 14px;
        }
    }
    .menu-item{
        background-color: white;
        margin: 18px 10px;
        min-height: 131px;
        border-radius: 8px;
    }
    .product-img{
        margin: 16px;
        object-fit: cover;
        width: 80px;
        height: 80px;
        border-radius: 8px;
    }

    @media(max-width: 768px){
      .product-img{
        margin: 18px;
        object-fit: cover;
        width: 94px;
        height: 94px;
        border-radius: 8px;
      }
    }
    .description{
      color: #A3AFC0;
      font-weight: 400;
      font-size: 14px;
      margin: 10px;
    }
    .no-products-available{
      h4{
          margin:10px;
          margin-left: 30px;
          margin-right: 30px;
          color: #070314;
          font-weight: 600;
          font-size: 20px;
        }
    }

    .price-no-desc{
      position: absolute;
      bottom: 8px;
    }

</style>