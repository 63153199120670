<template>
    <div id="whole-page">
        <b-container fluid="md" class="inner-container" id="header-container">
            <b-row>
                <b-col cols="12" offset-md="1" md="5" order-md="2">
                  <div class="order-panel" v-if="venue != null">
                       <lg-order-panel :order="order" :venue="venue" buttons="false" :fees="fees"></lg-order-panel>
                  </div>
                </b-col>
                <b-col cols="10" offset="1" offset-md="0" md="6" class="inline-form-panel">
                  <b-row v-if="venue != null">
                    <h3>{{venue.name}}</h3>
                  </b-row>
                  <b-row>
                    <p>{{deliveryType}}</p>
                  </b-row>
                  <b-row>
                    <b-form class="w-100 checkout-form">
                      <div class="form-group row">
                        <div class="col-md-6 col-12">
                          <label for="name" class="col-12 pl-0 col-form-label text-md">Your Name</label>
                          <input id="name" type="text" class="form-control"  name="name" v-model="deliveryDetails.name" required autocomplete="name" autofocus>
                        </div>
                        <div class="col-md-6 col-12">
                          <label for="phone" class="col-12 pl-0 col-form-label text-md">Contact Number</label>
                          <input id="phone" type="tel" class="form-control"  name="phone" v-model="deliveryDetails.phone" required autocomplete="phone" pattern="^\+?(?:\d\s?){10,12}$">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-4 col-12" v-if="this.order.type === 3">
                          <label for="delivery_time" class="col-12 pl-0 col-form-label text-md">Delivery Time</label>
                          <select id="delivery_time" class="form-control" name="delivery_time" v-model="deliveryDetails.delivery_time" required>
                            <option v-for="time in possibleDeliveryTimes" :key="time" v-bind:value="time">{{time}}</option>
                          </select>
                        </div>
                        <div class="col-md-4 col-12" v-if="this.order.type === 0">
                          <label for="collection_time" class="col-12 pl-0 col-form-label text-md">Collection Time</label>
                          <select id="collection_time" class="form-control" name="collection_time" v-model="deliveryDetails.collection_time" required>
                            <option v-for="time in possibleCollectionTimes" :key="time" v-bind:value="time">{{time}}</option>
                          </select>
                        </div>
                        <div class="col-md-4 col-12"  v-if="this.order.type === 3">
                          <label for="house_number" class="col-12 pl-0 col-form-label text-md ">House Number</label>
                          <input id="house_number" type="text" class="form-control"  name="house_number" v-model="deliveryDetails.house_number">
                        </div>
                        <div class="col-md-4 col-12"  v-if="this.order.type === 3">
                          <label for="address_line_1" class="col-12 pl-0 col-form-label text-md ">Address Line 1</label>
                          <input id="address_line_1" type="text" class="form-control"  name="address_line_1" v-model="deliveryDetails.address_line_1">
                        </div>
                        <div class="col-md-4 col-12" v-if="this.order.type === 3">
                          <label for="address_line_2" class="col-12 pl-0 col-form-label text-md ">Address Line 2</label>
                          <input id="address_line_2" type="text" class="form-control"  name="address_line_2" v-model="deliveryDetails.address_line_2">
                        </div>
                        <div class="col-md-4 col-12"  v-if="this.order.type === 3">
                          <label for="city" class="col-12 pl-0 col-form-label text-md ">City</label>
                          <input id="city" type="text" class="form-control"  name="house_number" v-model="deliveryDetails.city">
                        </div>
                        <div class="col-md-4 col-12" >
                          <label for="postcode" class="col-12 pl-0 col-form-label text-md">Postcode</label>
                          <input id="postcode" type="text" class="form-control"  name="postcode" v-model="deliveryDetails.postcode">
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-12">
                          <label for="comments" class="col-12 pl-0 col-form-label text-md">Comments</label>
                          <textarea id="comments" type="text" class="form-control"  name="comments" v-model="deliveryDetails.comments"></textarea>
                        </div>
                      </div>
                    </b-form>
                  </b-row>
<!--                  <hr>-->
                  <b-row>
                    <form id="payment-form" class="col-12">
                      <div id="card-element" @change="change()"><!--Stripe.js injects the Card Element--></div>
                      <button id="submit" @click.prevent="handlePayment()" :disabled="paymentInProgress === true">
                        <div class="spinner" v-show="paymentInProgress === true" id="spinner"></div>
                        <span id="button-text" v-show="paymentInProgress === false">Place {{deliveryType}} Order</span>
                      </button>
                      <p id="card-error" role="alert"></p>
                      <p class="result-message d-none">
                          Payment succeeded, see the result in your
                          <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.
                      </p>
                    </form>
                  </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from "@/main";
import LgOrderPanel from "@/components/LgOrderPanel";
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'
export default {
  name: 'Checkout',
  components:{
    LgOrderPanel,
  },
    data() {
        return {
            order: {
                type: null,
                items: [],
                venue: null,
            },
            deliveryDetails:{
                comments: null,
                postcode: null,
              house_number: null,
              address_line_1: null,
              address_line_2: null,
              city: null,
              collection_time: null,
              delivery_time: null,
              name: null,
              phone: null
            },
            venue: null,
            possibleDeliveryTimes: [],
            possibleCollectionTimes: [],
            formErrors: null,
          loading: false,
          fees: 0,
          stripe: {
            spk: null,
            stripe: undefined,
            card: undefined,
            msg: 'Pay here',
            lockSubmit: false
          },
          paymentInProgress: false
        }
    },
    computed:{
      phoneNumber(){
        return this.deliveryDetails.phone;
      },
      userSignedIn() {
        return this.$store.getters.userSignedIn;
      },
      amount()
      {
        var orderSubtotal = 0;
        var reduce = this.order.items.reduce(function (acc, item) {
          var tot = (item.pricePerItem * item.quantity);
          if(item.combined_products && item.combined_products.length > 0)
          {
            tot = tot + (item.combined_products[0].price * item.quantity);
          }
          return acc + tot;
        },0)
        orderSubtotal = orderSubtotal + reduce;
        if(this.order.type === 3)//delivery
        {
          orderSubtotal = orderSubtotal + this.venue.delivery_fee_double;
        }
        orderSubtotal = orderSubtotal + (this.fees);
        return orderSubtotal;
      },
      deliveryType(){
        switch(this.order.type)
        {
          case 0:
            return 'Click & Collect';
          case 3:
            return 'Delivery';
          default:
            return '';
        }
      }
    },
  watch: {
    phoneNumber()
    {
        this.deliveryDetails.phone = new AsYouType('GB').input(this.deliveryDetails.phone);
    }
  },
    methods:{
      handlePayment(){
        this.paymentInProgress = true;
        let self = this;
        var validateform = this.validateCheckoutForm();
        console.log(validateform);
        if(validateform)
        {
          //Now let's do a payment
          //First we tell our server that there's an order.
          let config = {
            headers: {
              'Authorization': 'Bearer ' + self.$store.getters.accessToken,
            }
          }
          // unauthorised = access token wrong, so we should try and refresh
          let uuid = this.uuidv4();
          axios.post('/api/customer/venue/' + self.order.venue +'/order/create', {
              'order_type': self.order.type,
              'name': self.deliveryDetails.name,
              'phone': self.deliveryDetails.phone,
              'collection_time': self.deliveryDetails.collection_time,
              'delivery_time': self.deliveryDetails.delivery_time,
              'uuid': uuid,
              'address_line_1': self.deliveryDetails.address_line_1,
              'address_line_2': self.deliveryDetails.address_line_2,
              'city' : self.deliveryDetails.city,
              'state': null,
              'postal_code': self.deliveryDetails.postcode,
              'country': 'United Kingdom',
              'products': self.order.items,
              'comments': self.deliveryDetails.comments,
              'expectedPrice': self.amount

          },config).then(function (response) {
            //next bit here
              console.log(response.data);
              self.stripe.stripe.confirmCardPayment(response.data.client_secret,{
                payment_method: {
                  card: self.stripe.card
                }
              }).then(function(result) {
                if (result.error) {
                  // Show error to your customer
                  alert(result.error.message);
                  self.paymentInProgress = false;
                } else {
                  let savedvenue = self.order.venue;
                  axios.post('/api/customer/venue/' + self.order.venue +'/orders/' + response.data.order_id + '/pay', {
                      "save_card":false
                  },config).then(function () {
                    //then reset/clear order everywhere
                    self.order = {
                      type: null,
                      items: [],
                      venue: null,
                    }
                    self.$store.commit('orderCompleted');
                    self.paymentInProgress = false;
                    //show confirmed
                    self.$toastr.s("THANKS",'Your order was confirmed');
                    //done
                    self.$router.push('/success?order='+response.data.order_id+'&venue='+savedvenue);
                  },);
                }
              });
          });
        }
        else{
          //validation fails
          self.paymentInProgress = false;
        }

      },
      uuidv4(){
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      },
      validateCheckoutForm(){
        console.log('called');
        var err = false;
        if(this.deliveryDetails.name == null || this.deliveryDetails.name === "")
        {
          this.$toastr.e('A name must be set')
          err = true;
        }
        if(this.deliveryDetails.phone == null || this.deliveryDetails.phone === "")
        {
          console.log("err phone");
          this.$toastr.e('A phone must be set')
          err = true;
        }
        else{
          try{
            const parsedPN = parsePhoneNumber(this.deliveryDetails.phone,'GB');
            if(!parsedPN.isValid())
            {
              console.log("err invalid phone");
              this.$toastr.e('Your Phone Number is Invalid')
              err = true;
            }
          }
          catch (e){
            console.log("err invalid phone");
            this.$toastr.e('Your Phone Number is Invalid')
            err = true;
          }
        }
        if(this.deliveryType === 'Delivery')
        {

          if(this.deliveryDetails.delivery_time == null || this.deliveryDetails.delivery_time === "")
          {
            console.log("err time");
            this.$toastr.e('A delivery time must be set')
            err = true;
          }
          if(this.deliveryDetails.house_number == null || this.deliveryDetails.house_number === "")
          {
            console.log("err house");
            this.$toastr.e('A house number must be set')
            err = true;
          }
          if(this.deliveryDetails.postcode == null || this.deliveryDetails.postcode === "")
          {
            console.log("err postcode");
            this.$toastr.e('A postcode must be set')
            err = true;
          }
          if(this.deliveryDetails.address_line_1 == null || this.deliveryDetails.address_line_1 === "")
          {
            console.log("err addr");
            this.$toastr.e('An address must be set')
            err = true;
          }
          if(this.deliveryDetails.city == null || this.deliveryDetails.city === "")
          {
            console.log("err city");
            this.$toastr.e('A city must be set')
            err = true;
          }
        }
        else{
          if(this.deliveryDetails.collection_time == null || this.deliveryDetails.collection_time === "")
          {
            console.log("err time");
            this.$toastr.e('A collection_time must be set')
            err = true;
          }
        }
        return !err; //inverse as we want NO errors
      },
      change(event){
        document.querySelector("button").disabled = event.empty;
        document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
      }
    },
    mounted() {
        //check if logged in
        if(!this.userSignedIn)
        {
          //if not redirect to login
          this.$router.push('/login');
        }
        else{
          this.order = this.$store.getters.order;
          this.deliveryDetails.name = this.$store.getters.name;
          this.deliveryDetails.postcode = this.$store.getters.deliveryPostcode;
          let self = this;
          if(this.order != null)
          {
            axios.get('api/customer/venue/'+this.order.venue).then(function (response2) {
              self.venue = response2.data;
            }).catch(function (err) {
              try{
                console.log(err);
                if(err.response.status === 404)
                {
                  // self.$router.push('/404');
                  console.log(err.response);
                }
                else{
                  console.log(err.response);
                }
              }
              catch(err2){
                console.log(err2)
              }
            });
          }
          else{
            this.$store.commit('setGlobalError','Your order did not have an associated venue');
            this.$router.back();
          }
          if(this.order.items.length === 0){
            this.$store.commit('setGlobalError','You haven\'t added any items to your basket');
            this.$router.back();
          }
          axios.get('api/customer/venue/'+this.order.venue+'/delivery-slots').then(function (response2) {
            self.possibleDeliveryTimes = response2.data;
          });
          axios.get('api/customer/venue/'+this.order.venue+'/collection-slots').then(function (response4) {
            self.possibleCollectionTimes = response4.data;
          });
          let config = {
            headers: {
              'Authorization': 'Bearer ' + self.$store.getters.accessToken,
            }
          }
          axios.post('/api/customer/venue/' + self.order.venue +'/order/check-fees', {
            'products': self.order.items,

          },config).then(function (response) {
              self.fees = response.data/100;
          });
          this.stripe.stripe = window.Stripe(this.$store.getters.stripeKey,{locale:'en-GB'});
          var elements = this.stripe.stripe.elements();
          var style = {
            base: {
              color: "#32325d",
              fontFamily: 'Arial, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#32325d"
              }
            },
            invalid: {
              fontFamily: 'Arial, sans-serif',
              color: "#fa755a",
              iconColor: "#fa755a"
            }
          };
          this.stripe.card = elements.create("card", {hidePostalCode: true,style: style });
          // Stripe injects an iframe into the DOM
          this.stripe.card.mount("#card-element");
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.checkout-form{
  input{
    border-radius: 8px;
  }
  select{
    border-radius: 8px;
  }
  label{
    font-size: 14px;
  }
  textarea{
    border-radius: 8px;
  }
}
.inline-form-panel{
  margin-top: 30px;
  text-align: left;
  p{
    font-weight: 700;
  }
}
#payment-form{
  //width: 30vw;
  //min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
  }
  #button-text{
    font-size: 14px;
    font-weight: 700;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
  #card-element {
    border-radius: 8px 8px 0 0 ;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    width: 100%;
    background: white;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  button {
    background: linear-gradient(270deg,#71C1FF,#6F47FF);
    color: #ffffff;
    border-radius: 0 0 8px 8px;
    border: 0;
    padding: 10px 16px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
}
@media only screen and (max-width: 768px){
  #payment-form{
    box-shadow: none;
    padding: 0px !important;
  }
}
.inner-container{
  min-height: 100vh;
}
#whole-page{
  overscroll-behavior: none;
}
</style>
