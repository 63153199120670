<template>
  <div id="whole-page">
    <back-to-venue-button></back-to-venue-button>
    <b-container fluid="lg" class="inner-container" id="header-container">

      <!--    {{ $route.params.venueslug }}-->
      <div class="profile-container">
        <b-row>
          <b-col cols="12" md="3">
            <div class="user-info-box" @click="$router.push('/user')">
              <div class="content">
                <img src="@/assets/profile.svg">
                <h2>{{ user.name }}</h2>
                <div class="info">
                  {{ user.email }}
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" offset-lg="1" md="8" class="row rhs">
            <div class="upcoming col-12">
              <h3>Upcoming Orders</h3>
              <div v-if="upcomingOrders.length === 0">
                  <div class="upcoming-order row">
                    <img class=" col-12 col-md-4 u-o-logo" src="@/assets/logo.svg" alt="image">
                    <div class="u-o-info col-12 col-md-8">
                      <div class="row">
                        <div class="col-12">
                          <h4>Nothing To Show</h4>
                          <h5>Future orders made will appear here...</h5>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div v-else>
                <div class="upcoming-order row" :class="{'cancelled-order': (order.booking != null && order.booking.status === 9)}" v-for="order in upcomingOrders" :key="order.id">
                 <img class="u-o-img col-12 col-md-4 p-0" :src="getOrderImage(order)" v-if="order.booking != null" alt="image">
                 <img class="u-o-vector col-12 col-md-4 non-venue-img" :src="getOrderImage(order)" v-else alt="image">
                 <div class="u-o-info col-12 col-md-8 row">
                   <div class="col-12 row">
                     <div class="col-6 col-md-4 link-header">
                       <a :href="/v/ + getOrderVenueSlug(order)">{{getOrderVenueName(order)}}</a>
                       <h5>{{getOrderType(order)}}</h5>
                     </div>
                     <div class="offset-1 offset-md-3 col-5 mt-2" v-if="order.booking != null">
                        <h1>£{{(order.booking.balance_remaining / 100).toFixed(2)}}</h1>
                        <h3>Balance Remaining</h3>
                     </div>
                     <div class="offset-1 offset-md-3 col-5 mt-2" v-else>
                       <h1>£{{(order.total_price / 100).toFixed(2)}}</h1>
                       <h3>Total Cost</h3>
                     </div>
                   </div>
                   <div class="col-12 row middle-row" v-if="order.booking != null">
                     <div class="col-4">
                       <div class="smalltoptext">Guests</div>
                       <div class="maintext">{{order.booking.tables[0] != null ? order.booking.tables[0].covers : 'N/A' }}</div>
                     </div>
                     <div class="col-4">
                       <div class="smalltoptext">Date</div>
                       <div class="maintext">{{ $date(order.booking.date, "YYYY-MM-DD").format('DD MMM YYYY') }}</div>
                     </div>
                     <div class="col-4">
                       <div class="smalltoptext">Time</div>
                       <div class="maintext">{{ order.booking.tables[0].time }}</div>
                     </div>
                   </div>
                   <div class="col-12 row middle-row" v-else>
                     <div class="col-4">
                       <div class="smalltoptext" v-if="order['food-order']['order_type_text'] === 'Collection'">Order Time</div>
                       <div class="smalltoptext" v-else>Time</div>
                       <div class="maintext" v-if="order['food-order']['order_type_text'] === 'Delivery'">{{ order['food-order']['delivery']['time']}}</div>
                       <div class="maintext" v-else>{{ $date.unix(order['created_at']).format('HH:ss')}}</div>
                     </div>
                     <div class="col-4">
                       <div class="smalltoptext">Date</div>
                       <div class="maintext">{{ $date.unix(order['created_at']).format('DD MMM YYYY') }}</div>
                     </div>
                     <div class="col-4" v-if="order['food-order']['order_type_text'] === 'Collection'">
                       <div class="smalltoptext">Collection Time</div>
                       <div class="maintext">{{order['food-order']['collection'] != null ? order['food-order']['collection']['time'] : 'N/A' }}</div>
                     </div>
                     <div class="col-4" v-else-if="order['food-order']['order_type_text'] === 'Delivery'">
                       <div class="smalltoptext">Postcode</div>
                       <div class="maintext">{{  order['food-order']['delivery']['postcode'] }}</div>
                     </div>
                     <div class="col-4" v-else-if="order['food-order']['order_type_text'] === 'Table'">
                       <div class="smalltoptext">Table</div>
                       <div class="maintext">{{order['food-order']['table']['table_name']}}</div>
                     </div>
                   </div>

                   <div class="col-12" v-if="order.booking != null && order.booking.status === 9">
                     <div class="btn btn-outline-danger w-100">Order Cancelled</div>
                   </div>

                   <router-link v-else :to="'/v/' + getOrderVenueSlug(order) + '/order/' + order.id" class="btn btn-voi col-12">
                     <div class="col-12">View Order Information</div>
                   </router-link>
                 </div>
              </div>
              </div>
            </div>
            <div class="upcoming col-12">
              <h3>Completed Orders</h3>
              <div class="upcoming-order row" :class="{'cancelled-order': (order.booking != null && order.booking.status === 9)}" v-for="order in completedOrders" :key="order.id">
                <img class="u-o-img col-12 col-md-4 p-0" :src="getOrderImage(order)" v-if="order.booking != null" alt="image">
                <img class="u-o-vector col-12 col-md-4 non-venue-img" :src="getOrderImage(order)" v-else alt="image">
                <div class="u-o-info col-12 col-md-8 row">
                  <div class="col-12 row">
                    <div class="col-6 col-md-4 link-header">
                      <a :href="/v/ + getOrderVenueSlug(order)">{{getOrderVenueName(order)}}</a>
                      <h5>{{getOrderType(order)}}</h5>
                    </div>
                    <div class="offset-1 offset-md-3 col-5 mt-2" v-if="order.booking != null">
                      <h1>£{{(order.booking.balance_remaining / 100).toFixed(2)}}</h1>
                      <h3>Balance Remaining</h3>
                    </div>
                    <div class="offset-1 offset-md-3 col-5 mt-2" v-else>
                      <h1>£{{(order.total_price / 100).toFixed(2)}}</h1>
                      <h3>Total Cost</h3>
                    </div>
                  </div>
                  <div class="col-12 row middle-row" v-if="order.booking != null">
                    <div class="col-4">
                      <div class="smalltoptext">Guests</div>
                      <div class="maintext">{{order.booking.tables[0] != null ? order.booking.tables[0].covers : 'N/A' }}</div>
                    </div>
                    <div class="col-4">
                      <div class="smalltoptext">Date</div>
                      <div class="maintext">{{ $date(order.booking.date, "YYYY-MM-DD").format('DD MMM YYYY') }}</div>
                    </div>
                    <div class="col-4">
                      <div class="smalltoptext">Time</div>
                      <div class="maintext">{{order.booking.tables[0] != null ? order.booking.tables[0].time.slice(0,-3) : 'N/A' }}</div>
                    </div>
                  </div>
                  <div class="col-12 row middle-row" v-else>
                    <div class="col-4">
                      <div class="smalltoptext" v-if="order['food-order']['order_type_text'] === 'Collection'">Order Time</div>
                      <div class="smalltoptext" v-else>Time</div>
                      <div class="maintext" v-if="order['food-order']['order_type_text'] === 'Delivery'">{{ order['food-order']['delivery']['time']}}</div>
                      <div class="maintext" v-else>{{ $date.unix(order['created_at']).format('HH:ss')}}</div>
                    </div>
                    <div class="col-4">
                      <div class="smalltoptext">Date</div>
                      <div class="maintext">{{ $date.unix(order['created_at']).format('DD MMM YYYY') }}</div>
                    </div>
                    <div class="col-4" v-if="order['food-order']['order_type_text'] === 'Collection'">
                      <div class="smalltoptext">Collection Time</div>
                      <div class="maintext">{{order['food-order']['collection'] != null ? order['food-order']['collection']['time'] : 'N/A' }}</div>
                    </div>
                    <div class="col-4" v-else-if="order['food-order']['order_type_text'] === 'Delivery'">
                      <div class="smalltoptext">Postcode</div>
                      <div class="maintext">{{  order['food-order']['delivery']['postcode'] }}</div>
                    </div>
                    <div class="col-4" v-else-if="order['food-order']['order_type_text'] === 'Table'">
                      <div class="smalltoptext">Table</div>
                      <div class="maintext">{{order['food-order']['table']['table_name']}}</div>
                    </div>
                  </div>
                  <div class="col-12 row">
                    <div class="col-12" v-if="order.booking != null && order.booking.status === 9">
                      <div class="btn btn-outline-danger w-100">Order Cancelled</div>
                    </div>

                    <router-link v-else :to="'/v/' + getOrderVenueSlug(order) + '/order/' + order.id" class="btn btn-voi col-12">
                      <div class="col-12">View Order Information</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "@/main";
import BackToVenueButton from "@/components/BackToVenueButton";

export default {
  name: 'Orders',
  components: {BackToVenueButton},
  props: {
    msg: String
  },
  data() {
    return {
      user: {
        name: null,
        email: null
      },
      base_orders: null
    }
  },
  mounted: function (){
    this.order = this.$store.getters.order;
    let self = this;
    let config = {
      headers: {
        'Authorization': 'Bearer ' + self.$store.getters.accessToken,
      }
    }
    if(this.order != null)
    {
      axios.get('api/user',config).then(function (response2) {
        self.user = response2.data;
      }).catch(function () {
        self.$router.push('/login');
      }).then(function (){
        axios.get('api/customer/user/orders',config).then(function (response3) {
          self.base_orders = response3.data;
          console.log(self.base_orders);
        }).catch(function () {
          alert('fail')
        }).then(function (){

        });
      });
    }
  },
  methods: {
    getOrderImage(order)
    {
       if(order.booking != null && order.booking.tables[0] != null)
       {
         //then it's a table order
         return order.booking.tables[0].image;
       }
       else if(order['food-order'] != null && order['food-order']['order_type_text'] === 'Delivery'){
         return 'https://clubglobal-2020.s3-eu-west-1.amazonaws.com/delivery_service.svg'
       }
       else if(order['food-order'] != null && order['food-order']['order_type_text'] === 'Collection'){
         return 'https://clubglobal-2020.s3-eu-west-1.amazonaws.com/click_collect_service.svg'
       }
       else{
         return 'https://clubglobal-2020.s3-eu-west-1.amazonaws.com/table_service.svg'
       }
    },
    getOrderVenueName(order)
    {
      if(order.booking != null)
      {
        //then it's a table order
        return order.booking.venue.name;
      }
      else{
        return order['food-order']['venue-name'];
      }
    },
    getOrderVenueSlug(order)
    {
      if(order.booking != null)
      {
        //then it's a table order
        return order.booking.venue.slug;
      }
      else{
        return order['food-order']['venue-slug'];
      }
    },
    getOrderType(order)
    {
      if(order.booking != null)
      {
        //then it's a table order
        return 'Table Booking'
      }
      else{
        return order['food-order']['order_type_text'];
      }
    }
  },
  computed: {
    completedOrders(){
      let self = this;
      if(this.base_orders)
      {
        return this.base_orders.filter(function (item){
          //check if an item is "upcoming" according to the app team logic
          if(item.booking != null)
          {
            var booking = item.booking;
            var d2 = self.$date(booking.date, "YYYY-MM-DD");
            if(d2.isToday())
            {
              if(booking.balance_remaining > 0 && booking.status === 8){
                return true;
              }
              else{
                if(booking.balance_remaining <= 0 && booking.status >= 4){
                  return true;
                }
                else{
                  return false
                }
              }
            }
            else{
              return d2 < self.$date();
            }
          }
          else{
            var day = self.$date.unix(item.created_at);
            if(day.isToday()){
              return false;
            }
            else{
              return day < self.$date();
            }
          }
        }).filter(function (item){
          if(item['food-order'] == null)
          {
            if(item.booking === null)
            {
              return false;
            }
            return true;
          }
          else{
            if(item['food-order']['order_type_text'] === null)
            {
              return false;
            }
            else{
              return true;
            }
          }
        })
      }
      else return [];
    },
    upcomingOrders(){
      let self = this;
      if(this.base_orders)
      {
        let filter1 = this.base_orders.slice().sort(function(item1, item2){
          let item1Date = item1.booking != null ? self.$date(item1.booking.date, 'YYYY-MM-DD').unix() : self.$date(item1.created_at).startOf('date').unix();
          let item2Date = item2.booking != null ? self.$date(item2.booking.date, 'YYYY-MM-DD').unix() : self.$date(item2.created_at).startOf('date').unix();

          if(item1Date == item2Date){
            return 0;
          }
          return item1Date < item2Date ? 1 : -1;
        }).filter(function (item){
          //check if an item is "upcoming" according to the app team logic
          if([0, 1].includes(item.status)){
            return false;
          }

          if(item.booking != null)
          {
            var booking = item.booking;
            var d2 = self.$date(booking.date, "YYYY-MM-DD");
            if(d2.isToday())
            {
              if(booking.balance_remaining > 0 && booking.status < 8){

                return true;
              }
              else if(booking.balance_remaining <= 0 && booking.status < 4){
                return true;
              }
              else{
                return d2 > self.$date();
              }
            }
            else{
              return d2 > self.$date();
            }
          }
          else{
            var day = self.$date.unix(item.created_at);
            if(day.isToday()){
              console.log(day);
              return true;
            }
            else{
              return item.created_at > self.$date().unix();
            }
          }
        });
        console.log(filter1);
        return filter1.filter(function (item){
          if(item['food-order'] == null)
          {
            if(item.booking === null)
            {
              return false;
            }
            return true;
          }
          else{
            if(item['food-order']['order_type_text'] != null)
            {
              return true;
            }
            else{
              return false;
            }
          }
        })
      }
      else return [];
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.rhs{
  text-align: left;
}
.profile-container{
  padding-top: 33px;
  h4{
    font-size: 20px;
    font-weight: 600;
    color: #7290FF;
    padding-top: 13px;
  }
  .link-header{
    a{
      font-size: 20px;
      font-weight: 600;
      color: #7290FF;
      padding-top: 13px;
    }
  }
  h5{
    font-weight: 700;
    font-size: 14px;
    color:#A3AFC0
  }
  h3{
    font-size: 20px;
    font-weight: 600;
    color: #070314;
    margin-top: 10px;
  }
  h2{
    font-size: 28px;
    font-weight: 400;
    color: #070314;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
.user-info-box{
  border-radius: 16px;
  background: white;
  border: 1px solid #CBD7E8;
  padding: 33px;
}
@media(max-width: 768px){
  .rhs{
    text-align: center;
    margin-left: 0 !important;
  }
  .user-info-box{
     padding-top: 50px;
     padding-bottom: 50px;
     padding-left: 5px;
    padding-right: 5px;
  }
  .u-o-img{
    border-radius: 16px 0 0 0 !important;
  }
  .u-o-vector{
    border-radius: 16px 0 0 0 !important;
  }
  .u-o-info{
    margin-left: 0 !important;
  }
  .maintext{
    font-size: 12px !important;
  }
  .smalltoptext{
    font-size: 9px !important;
  }
}
.info{
  color: #7290FF;
  font-size: 14px;
  font-weight: 400;
  vertical-align: top;
  overflow-wrap: anywhere;
}
.upcoming-order{
  background: white;
  border-radius: 16px;
  margin-bottom: 40px;
  margin-top: 10px;
  overflow: hidden;

  &.cancelled-order {
    position: relative;

    &::after {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background-color: rgba(#ffffff, 0.6);
    }
  }
}

.u-o-img{
  border-radius: 16px 0 0 16px;
  overflow: hidden;
  object-fit: cover;
  position: relative;  margin-right: 15px;
  max-height: 240px;
}
.u-o-vector{
  border-radius: 16px 0 0 16px;
  overflow: hidden;
  padding: 18px !important;
  position: relative;
  margin-right: 15px;
}
.u-o-logo{
  max-height: 100px;
  padding: 15px;
}
.u-o-info{
  margin-bottom: 12px;
  h1{
    font-size: 20px;
    font-weight: 600;
    color: #070314;
  }
  h3{
    font-size: 14px;
    font-weight: 700;
    color: #070314;
  }
}
.btn-voi{
  background: linear-gradient(270deg,#71C1FF,#6F47FF);
  color: #ffffff;
  border-radius: 8px;
  border: 0;
  padding: 10px 16px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.smalltoptext{
  margin-top: 16px;
  font-size: 10px;
  color: #A3AFC0;
  font-weight: 600;
}
.maintext{
  font-size: 14px;
  font-weight: 400;
  color: #070314;
}
.middle-row{
  margin-bottom: 24px;
}
.non-venue-img{
  padding: 10px;
  background:linear-gradient(270deg,#F0ECFF,#D0EAFF);
}
</style>
