<template>
  <div v-if="$store.getters.orderVenue != null">
    <router-link  v-show="venueurl != null" class="row col-12 b2v-btn d-lg-none" :to="venueurl">
      <img src="@/assets/left_chevron.svg" class="b2v-icon col-2">
      <p class="col-5 b2v-text d-flex align-items-center">Return to venue</p>
    </router-link>
    <router-link  v-show="venueurl != null" class="row col-12 b2v-btn-desktop d-none d-lg-block" :to="venueurl">
<!--      <div class="offset-4 col-4 row">-->
<!--        <img src="@/assets/left_chevron.svg" class="b2v-icon col-2">-->
<!--        <p class="col-5 b2v-text d-flex align-items-center">Return to venue</p>-->
<!--      </div>-->
      <button type="button" @click="reset()" class="btn btn-info col-2 larger-button">
        <img src="@/assets/left_chevron.svg" class="">
        <span>
          Return to Venue
        </span>
      </button>
    </router-link>
  </div>
</template>

<script>
    export default {
      name: "BackToVenueButton",
      // data() {
      //     return {
      //
      //     }
      // },
      // mounted() {
      //     // console.log('about to log menucat');
      //     // console.log(this.category);
      //     // console.log('about to log menucat');
      // },
      computed: {
        venueurl() {
          if (this.$store.getters.orderVenue != null) {
            return '/v/' + this.$store.getters.orderVenue;
          } else {
            return null;
          }
        },
        // methods: {
        // callFunc(item)
        // {
        //     this.productSelected = item;
        //     this.$emit('product-selected', item)
        //     this.$bvModal.show('menuModal');
        // },
        // defaultDescription(description)
        // {
        //    if(description != null && description.length > 0)
        //    {
        //      return description;
        //    }
        //    else{
        //      return ""
        //    }
        // },
        // addProduct()
        // {
        //
        // },

        // }
      }
    }
</script>

<style scoped lang="scss">
    .b2v-btn{
      height: 40px;
      background: white;
      margin-left: 0;
      padding-left:0;
      margin-right:0;
      padding-right:0;
      width: 100%;
      border-top: 1px solid #CBD7E8;
      border-bottom: 1px solid #CBD7E8;
    }
    .b2v-icon{
      max-height: 30px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .b2v-text{
      text-align: left;
      font-weight: 700;
      font-size: 14px;
      padding-top: 8px;
      color: black;
      margin-left: -5%;
    }
    .larger-button{
      margin-top: 8px;
      background: white;
      border: 1px solid #7290FF;
      width: 100%;
      span{
        font-weight: 700;
        font-size: 14px;
        padding-top: 8px;
        color: black !important;
        margin-left: 10px;
      }
    }

</style>