<template>
    <div id="whole-page">
        <b-container fluid="md" class="inner-container" id="header-container">
            <div class="100vw">
              <b-row class="totalsrow">
                <b-col cols="4">
                  <h2 class="itemcount">Your Order</h2>
                </b-col>
                <b-col cols="7" class="st-price-col"> <b-button class="view-menu" @click="changeService()">Change Service Type</b-button></b-col>
              </b-row>
              <b-row class="product-rows">
                <b-col class="col-12 row product-row" v-for="itm in order.items" :key="itm.id">
                  <b-col class="btn-col" cols="5" sm="3">
                    <b-btn class="sub-btn inc-btn" @click="changeItmQuantity(itm,-1)">-</b-btn>
                    {{itm.quantity}}
                    <b-btn class="plus-btn inc-btn" @click="changeItmQuantity(itm,1)">+</b-btn>
                  </b-col>
                  <b-col cols="4" sm="5" class="name-col">{{itm.name}}</b-col>
                  <b-col cols="3" sm="4" class="price-col"  v-if="itm.combined_products && itm.combined_products.length > 0">£{{((itm.pricePerItem * itm.quantity) + (itm.combined_products[0].price * itm.quantity)).toFixed(2)}}</b-col>
                  <b-col cols="3" sm="4" class="price-col"  v-else>£{{(itm.pricePerItem * itm.quantity).toFixed(2)}}</b-col>
                  <b-col offset="5" cols="3" v-if="itm.combined_products && itm.combined_products.length > 0" class="combined-products-col">+{{itm.combined_products[0].name }}</b-col>
                  <b-col offset="1" cols="11" class="fullwidthline"></b-col>
                </b-col>
              </b-row>
            </div>
        </b-container>
      <lg-mobile-order-panel  :order="order" :venue="venue" :buttons="true" class="fixed-bottom row w-100"></lg-mobile-order-panel>
      <b-modal id="changeService" title="Change Service">
        <lg-order-type-selection :dt="venueHasTimeSlotsForDelivery" :ct="venueHasTimeSlotsForCollection" :venue="venue" :order="order" v-on:hideOrderTypeModal="hideOrderType()" :ismodal="true" v-on:viewMenu="isViewingMenu = true; hideOrderType()"></lg-order-type-selection>
        <template #modal-footer>
          <div class="w-100">
            <b-button
                variant="dark"
                size="sm"
                class="float-right"
                @click="$bvModal.hide('changeService')"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
</template>

<script>
import axios from "@/main";
import LgMobileOrderPanel from "@/components/LgMobileOrderPanel";
import LgOrderTypeSelection from "@/components/LgOrderTypeSelection";
export default {
  components: {
    LgMobileOrderPanel,
    LgOrderTypeSelection
  },
  name: 'MobileBasket',
    data() {
        return {
            order: {
                type: null,
                items: [],
                venue: null,
            },
            venue: null,
          venueHasTimeSlotsForDelivery: false,
          venueHasTimeSlotsForCollection: false,
        }
    },
    computed:{
      deliveryType(){
        switch(this.order.type)
        {
          case 0:
            return 'click & collect';
          case 3:
            return 'delivery';
          default:
            return null;
        }
      },
      calculateSubtotal()
      {
        var orderSubtotal = 0;
        var reduce = this.order.items.reduce(function (acc, item) {
          var tot = (item.pricePerItem * item.quantity);
          if(item.combined_products && item.combined_products.length > 0)
          {
            tot = tot + (item.combined_products[0].price * item.quantity);
          }
          return acc + tot;
        },0)
        orderSubtotal = orderSubtotal + reduce;
        if(this.order.type === 3)//delivery
        {
          orderSubtotal = orderSubtotal + this.venue.delivery_fee_double;
        }
        return orderSubtotal;
      },
      userSignedIn() {
        return this.$store.getters.userSignedIn;
      },
      amount()
      {
        var orderSubtotal = 0;
        var reduce = this.order.items.reduce(function (acc, item) {
          return acc + (item.pricePerItem * item.quantity);
        },0)
        orderSubtotal = orderSubtotal + reduce;
        if(this.order.type === 3)//delivery
        {
          orderSubtotal = orderSubtotal + this.venue.delivery_fee_double;
        }
        return orderSubtotal;
      },
      },
      methods: {
        hideOrderType(){
          this.$bvModal.hide('changeService');
        },
        changeService()
        {
          this.$bvModal.show('changeService');
        },
        removeCombinedProduct(itm){
          itm.combined_products = [];
        },
        checkout(){
          this.$store.commit('setFullOrder',this.order);
          this.$store.commit('setVenue',this.venue.slug);
          this.$router.push('/checkout');
        },
        forceServiceChange()
        {
          this.$emit('showServiceModal')
        },
        resetOrder()
        {
          this.order.items = [];
          this.order.venue = null;
          this.order.type = null;
          this.delivery_postcode = null;
          this.order.venue = this.venue.slug;
          this.$store.commit('clearOrder');
          this.$store.commit('setVenue',this.venue.slug);
        },
        changeItmQuantity(itm,quantity)
        {
          if(quantity < 0 && itm.quantity <= 1)
          {
            this.order.items.splice(this.order.items.findIndex(function(i){
              return i.id === itm.id;
            }), 1);
          }
          else{
            itm.quantity = itm.quantity + quantity;
          }
        }
      },
  mounted() {
    window.scrollTo(0, 0);
    //check if logged in
      this.order = this.$store.getters.order;
      console.log(this.order);
      let self = this;
      if(this.order != null)
      {
        axios.get('api/customer/venue/'+this.order.venue).then(function (response2) {
          self.venue = response2.data;
        }).catch(function (err) {
          try{
            console.log(err);
            if(err.response.status === 404)
            {
              // self.$router.push('/404');
              console.log(err.response);
            }
            else{
              console.log(err.response);
            }
          }
          catch(err2){
            console.log(err2)
          }
        });
      }
      else{
        this.$store.commit('setGlobalError','Your order did not have an associated venue');
        this.$router.back();
      }
      axios.get('api/customer/venue/'+self.order.venue+'/delivery-slots').then(function (response) {
        self.venueHasTimeSlotsForDelivery = response.data.length > 0;
      });
      axios.get('api/customer/venue/'+self.order.venue+'/collection-slots').then(function (response) {
        self.venueHasTimeSlotsForCollection = response.data.length > 0;
      });
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.checkout-form{
  input{
    border-radius: 8px;
  }
  select{
    border-radius: 8px;
  }
  label{
    font-size: 14px;
  }
  textarea{
    border-radius: 8px;
  }
}
.inline-form-panel{
  margin-top: 30px;
  text-align: left;
  p{
    font-weight: 700;
  }
}
#whole-page{
  min-height: 100vh;
  max-width: 100%; overflow-x: hidden;
}

  input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
  }
  #button-text{
    font-size: 14px;
    font-weight: 700;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .fixed-bottom{
    max-width: 100vw;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 12px;
  }
  #card-element {
    border-radius: 8px 8px 0 0 ;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    width: 100%;
    background: white;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  button {
    background: linear-gradient(270deg,#71C1FF,#6F47FF);
    color: #ffffff;
    border-radius: 0 0 8px 8px;
    border: 0;
    padding: 10px 16px;
    height: 44px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  h2{
    font-size: 20px;
    font-weight: 600;
    color: #070314;
    text-align: left;
  }
  .totalsrow{
    margin-top: 20px;
  }
  .view-menu{
    background: none;
    border: none;
    color: #7290FF;
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
  }
  .view-menu:hover{
    color: darken(#7196FF,20%);
  }
  .view-menu:active{
    color: darken(#7196FF,20%) !important;
    background: none !important;
    border: none !important;
  }
  .view-menu:focus{
    color: darken(#7196FF,20%) !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
.st-price-col{
  text-align: right !important;
}
.combined-products-col{
  font-size: 11px;
  padding-left: 32px;
  text-align: left;
  margin-top: -10px;
}
.price-col{
  font-weight: 600;
}
.totalsrow{
  margin-right: -80px;
}
.itemcount{
  margin-top: 0.5rem;
}
.plus-btn{
  border-radius: 50%;
  width: 32px;
  height:32px;
  padding: 0px !important;
  display: inline;  margin-left: 5px;
}
.sub-btn{
  border-radius: 50%;
  width: 32px;
  height:32px;
  padding: 0px !important;  display: inline;
  margin-right: 5px;
}
.product-row{
  margin-top: 8px;
}
.inc-btn{
  background: #A3AFC0;
}
.fullwidthline{
  border-bottom: 1px solid #A3AFC0;
  margin-bottom: 12px;
  margin-left: 22px;
  margin-top: 6px;
  color: #A3AFC0;
  opacity: 30%;
}
</style>
