<template>
  <div id="whole-page">
    <b-container fluid="lg" class="inner-container" id="header-container">
      <!--    {{ $route.params.venueslug }}-->
      <div class="profile-container">
        <b-row>
          <b-col cols="12" md="3">
            <div class="user-info-box" @click="$router.push('/user')">
              <div class="content">
                <img src="@/assets/profile.svg">
                <h2>{{ user.name }}</h2>
                <div class="info">
                  {{ user.email }}
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" offset-lg="1" md="8" class="row rhs">
            <div class="upcoming col-12">
              <h3>User Settings</h3>
              <form>
                <div class="form-group row mb-2 pb-3 border-bottom">
                  <b-col xl="8">
                    <label class='top-label' for="name">Enter your name</label>
                    <b-form-input class="" id='name' v-model="userdetails.name"></b-form-input>
                  </b-col>
                  <b-col xl="8" class="pt-2">
                    <label class='top-label' for="phone">Enter your phone</label>
                    <b-form-input class="" id='phone' v-model="userdetails.phone"></b-form-input>
                  </b-col>
                  <!--                  <b-col xl="8" class="pt-2">-->
                  <!--                    <label class='top-label' for="dob">Enter your date of birth (optional)</label>-->
                  <!--                    <b-form-datepicker class="" id='dob' v-model="userdetails.dob" placeholder="Optional"></b-form-datepicker>-->
                  <!--                  </b-col>-->
                  <b-col xl="8" class="row pt-2">
                    <label class="top-label col-12">Enter your date of birth (optional)</label>
                    <b-col cols="4" class="">
                      <label class='top-label' for="dob_day">Day</label>
                      <b-form-input class="" id='dob_day' v-model="userdetails.dob.day" placeholder="01" type="number" min="1" max="31" step="1"></b-form-input>
                    </b-col>
                    <b-col cols="4" class="">
                      <label class='top-label' for="dob_month">Month</label>
                      <b-form-input class="" id='dob_month' v-model="userdetails.dob.month" placeholder="03" type="number" min="1" max="12" step="1"></b-form-input>
                    </b-col>
                    <b-col cols="4" class="">
                      <label class='top-label' for="dob_year">Year</label>
                      <b-form-input class="" id='dob_year' v-model="userdetails.dob.year" placeholder="1994"  type="number" min="1900" max="2020" step="1"></b-form-input>
                    </b-col>
                  </b-col>
                </div>
                <div class="form-group row mb-2">
                  <b-col xl="8" class="pt-2">
                    <b-form-checkbox
                        id="marketingemail"
                        v-model="userdetails.marketing_email"
                        name="marketingemail">
                      Can we contact you by email for marketing purposes?</b-form-checkbox>
                  </b-col>
                  <b-col xl="8" class="pt-2">
                    <b-form-checkbox
                        id="marketingphone"
                        v-model="userdetails.marketing_phone"
                        name="marketingphone">
                      Can we contact you by phone for marketing purposes?</b-form-checkbox>
                  </b-col>
                </div>


                <div class="form-group row mb-0">
                  <div class="col-md-8 col-lg-4 offset-md-2 forgotlink row">
                    <button type="button"  @click="updateUser()" class="btn btn-primary w-100 mb-2 col-12 updatebtn">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "@/main";

export default {
  name: 'Profile',
  data() {
    return {
      user: {
        name: null,
        email: null
      },
      userdetails: {
        name: null,
        phone: null,
        dob:{
          day: null,
          month: null,
          year: null
        },
        marketing_email: null,
        marketing_phone: null
      }
    }
  },
  mounted: function (){
    this.order = this.$store.getters.order;
    let self = this;
    let config = {
      headers: {
        'Authorization': 'Bearer ' + self.$store.getters.accessToken,
      }
    }
    if(this.order != null)
    {
      axios.get('api/user',config).then(function (response2) {
        self.user = response2.data;
        self.userdetails.name = self.user.name;
        self.userdetails.phone = self.user.phone;
        let dobexplode = self.user.dob.split('/');
        self.userdetails.dob.day = dobexplode[0];
        self.userdetails.dob.month = dobexplode[1];
        self.userdetails.dob.year = dobexplode[2];
        self.userdetails.marketing_email = self.user.marketing_email;
        self.userdetails.marketing_phone = self.user.marketing_phone;
      }).catch(function () {
        self.$router.push('/login');
      });
    }
  },
  methods: {
    updateUser() {
      this.isLoading = true;
      let self = this;
      let config = {
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.accessToken,
        }
      }
      axios.post('/api/user', {
        'phone': self.userdetails.phone,
        'dob': self.dateofbirth,
        'name': self.userdetails.name,
        'marketing_email': self.userdetails.marketing_email,
        'marketing_phone':self.userdetails.marketing_phone
      }, config).then(function () {
        self.$toastr.s("Saved!",'Your updates have been saved!');
      });
    }
  },
  computed: {
    dateofbirth()
    {
      if(this.userdetails.dob.day  != null && this.userdetails.dob.month  != null && this.userdetails.dob.year  != null)
      {
        return this.userdetails.dob.day + "/" + this.userdetails.dob.month + "/" + this.userdetails.dob.year
      }
      else{
        return null;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.forgotlink{
  margin-left: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.rhs{
  text-align: left;
}
.profile-container{
  padding-top: 33px;
  h4{
    font-size: 20px;
    font-weight: 600;
    color: #7290FF;
    padding-top: 13px;
  }
  h5{
    font-weight: 700;
    font-size: 14px;
    color:#A3AFC0
  }
  h3{
    font-size: 20px;
    font-weight: 600;
    color: #070314;
    margin-top: 10px;
  }
  h2{
    font-size: 28px;
    font-weight: 400;
    color: #070314;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
.user-info-box{
  border-radius: 16px;
  background: white;
  border: 1px solid #CBD7E8;
  padding: 33px;
}
@media(max-width: 768px){
  .rhs{
    text-align: center;
    margin-left: 0 !important;
  }
  .user-info-box{
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .u-o-img{
    border-radius: 16px 0 0 0 !important;
  }
  .u-o-vector{
    border-radius: 16px 0 0 0 !important;
  }
  .u-o-info{
    margin-left: 0 !important;
  }
  .maintext{
    font-size: 12px !important;
  }
  .smalltoptext{
    font-size: 9px !important;
  }
}
.upcoming{
  text-align: left;
}
.info{
  color: #7290FF;
  font-size: 14px;
  font-weight: 400;
  vertical-align: top;
  overflow-wrap: anywhere;
}
.upcoming-order{
  background: white;
  border-radius: 16px;
  margin-bottom: 40px;
  margin-top: 10px;
  overflow: hidden;
}

.u-o-img{
  border-radius: 16px 0 0 16px;
  overflow: hidden;
  object-fit: cover;
  position: relative;  margin-right: 15px;
  max-height: 240px;
}
.u-o-vector{
  border-radius: 16px 0 0 16px;
  overflow: hidden;
  padding: 18px !important;
  position: relative;
  margin-right: 15px;
}
.u-o-logo{
  max-height: 100px;
  padding: 15px;
}
.u-o-info{
  margin-bottom: 12px;
  h1{
    font-size: 20px;
    font-weight: 600;
    color: #070314;
  }
  h3{
    font-size: 14px;
    font-weight: 700;
    color: #070314;
  }
}
.btn-voi{
  background: linear-gradient(270deg,#71C1FF,#6F47FF);
  color: #ffffff;
  border-radius: 8px;
  border: 0;
  padding: 10px 16px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
}
.smalltoptext{
  margin-top: 16px;
  font-size: 10px;
  color: #A3AFC0;
  font-weight: 600;
}
.maintext{
  font-size: 14px;
  font-weight: 400;
  color: #070314;
}
.middle-row{
  margin-bottom: 24px;
}
.non-venue-img{
  padding: 10px;
  background:linear-gradient(270deg,#F0ECFF,#D0EAFF);
}
.updatebtn{
  border-radius: 8px;
  background: linear-gradient(270deg,#71C1FF,#6F47FF);
  border: none;
  margin-top: 10px;
  height: 40px;
  font-weight: 600;
}
</style>
