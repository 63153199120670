<template>
  <div id="app">
      <b-navbar toggleable="sm" class="navbar-lg">
        <b-container fluid="md">
          <b-navbar-brand to="/" class="home-link"><img class="navbar-logo" src="@/assets/logo.svg">Local Global</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
<!--          <b-navbar-nav>-->
<!--            <b-nav-item href="#">Link</b-nav-item>-->
<!--            <b-nav-item href="#" disabled>Disabled</b-nav-item>-->
<!--          </b-navbar-nav>-->

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
<!--            <b-nav-form>-->
<!--              <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>-->
<!--              <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>-->
<!--            </b-nav-form>-->

<!--            <b-nav-item-dropdown text="Lang" right>-->
<!--              <b-dropdown-item href="#">EN</b-dropdown-item>-->
<!--              <b-dropdown-item href="#">ES</b-dropdown-item>-->
<!--              <b-dropdown-item href="#">RU</b-dropdown-item>-->
<!--              <b-dropdown-item href="#">FA</b-dropdown-item>-->
<!--            </b-nav-item-dropdown>-->

            <b-nav-item-dropdown  v-if="userSignedIn" right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <img class="sign-in" src="@/assets/team_active.svg">
                <em>Welcome<span v-if="!user.has_fake_email">, {{user.name}}</span> </em>
              </template>
<!--              <b-dropdown-item href="/user">Profile</b-dropdown-item>-->
              <b-dropdown-item href="/user/order-history">Order History</b-dropdown-item>
              <b-dropdown-item href="#" @click="signOut()">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item to="/login" class="login-text" v-else>
                <img class="sign-in" src="@/assets/home_active.svg">
                Log In
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
        </b-container>
      </b-navbar>
      <b-container fluid class="wrapper-container">
         <router-view></router-view>
          <b-modal id="my-modal" v-show="userHasFakeEmail"  title="Sign In With Apple Registration">
              <p class="my-4">Thanks for signing in with Apple for the first time! In order to proceed, please confirm your email and name.</p>
              <b-row>
                  <b-col xl="8">
                      <label class='top-label' for="fakeemailname">Enter your name</label>
                      <b-form-input class="" id='fakeemailname' v-model="fakeemail.name" placeholder="Required"></b-form-input>
                  </b-col>
                  <b-col xl="8" class="pt-2">
                      <label class='top-label' for="fakeemailemail">Enter your email</label>
                      <b-form-input class="" id='fakeemailemail' v-model="fakeemail.email" placeholder="Required"></b-form-input>
                  </b-col>
                  <b-col xl="8" class="pt-2">
                      <label class='top-label' for="fakeemailphone">Enter your phone (optional)</label>
                      <b-form-input class="" id='fakeemailphone' v-model="fakeemail.phone" placeholder="Optional"></b-form-input>
                  </b-col>
                  <b-col xl="8" class="pt-2">
                      <label class='top-label' for="fakeemaildob">Enter your date of birth (optional)</label>
                      <b-form-datepicker class="" id='fakeemaildob' v-model="fakeemail.dob" placeholder="Optional"></b-form-datepicker>
                  </b-col>
                  <b-col xl="8" class="pt-2">
<!--                      <label class='top-label' for="fakeemaildob">></label>-->
                      <b-form-checkbox
                              id="fakeemailmarketingemail"
                              v-model="fakeemail.marketing_email"
                              name="fakeemailmarketingemail">
                      Can we contact you by email for marketing purposes?</b-form-checkbox>
                  </b-col>
                  <b-col xl="8" class="pt-2" v-if="fakeemail.phone != null">
                      <!--                      <label class='top-label' for="fakeemaildob">></label>-->
                      <b-form-checkbox
                              id="fakeemailmarketingphone"
                              v-model="fakeemail.marketing_phone"
                              name="fakeemailmarketingphone">
                      Can we contact you by phone for marketing purposes?</b-form-checkbox>
                  </b-col>
              </b-row>
              <template #modal-footer>
                  <div class="w-100">
                      <b-button
                              variant="primary"
                              size="sm"
                              class="float-right"
                              @click="submitEmail()"
                              :disabled="fakeemail.name == null || fakeemail.email == null"
                      >
                          Submit
                      </b-button>
                  </div>
              </template>
          </b-modal>
      </b-container>

  </div>

</template>

<script>
    import axios from './main';
    export default {
        name: "App",
        data() {
            return {
                fakeemail: {
                    name: null,
                    email: null,
                    phone: null,
                    dob: null,
                    marketing_email: null,
                    marketing_phone: null,
                }
            }
        },
        async mounted() {

            if (window.location.href.indexOf('login') === -1) {
                // console.log(this.$route.name)
                if (this.$store.getters.accessToken === null)//user should have an access token,  so we can check if it is set at all to initialise a check against browserstorage
                {
                    //try loading the state from the browser
                    let thisuser = this.$cookies.get("user");
                    if (thisuser != null) {
                        this.$store.commit('storeUser', thisuser);
                        let response = await this.checkSignedInUser();
                        // console.log(response)
                        this.$store.commit('userSignedIn', response);
                    } else {
                        //at this point we have no user, so we need to make them sign in if they are not.
                        this.$store.commit('userSignedIn', false);
                    }
                } else {
                    //the user has an access token, so we see if it is valid or not
                    let response2 = await this.checkSignedInUser();
                    this.$store.commit('userSignedIn', response2)

                }
            }


            // this.data_category =  JSON.parse(this.category);
            // this.data_venue_slug =  JSON.parse(this.venue_slug);
            // this.data_items =  JSON.parse(this.items);
            // Echo.channel('Category_' + this.data_category.id).listen('ProductCreated', (e) => {
            //     console.log('HAPPENED');
            //     this.data_items = e.products;
            // });
        },
        computed: {
            userSignedIn() {
                return this.$store.getters.userSignedIn;
            },
            user() {
                return this.$store.getters.user;
            },
            userHasFakeEmail() {
                // console.log('acessor' + this.$store.getters.userHasFakeEmail);
                return Boolean(this.$store.getters.userHasFakeEmail);
            }
        },
        methods: {
            signOut() {
                this.$cookies.remove('user');
                this.$store.commit('storeUser', {
                    name: null,
                    access_token: null,
                    refresh_token: null,
                    email: null
                });
                this.$store.commit('userSignedIn', false);
            },
            async checkSignedInUser() {
                let resp = null;
                let self = this;
                let config3 = {
                    headers: {
                        'Authorization': 'Bearer ' + self.$store.getters.accessToken,
                    }
                }
                await axios.get('api/user', config3).then(function (resp0) {
                    //we have a valid access token so no need to do anything else
                    // console.log('Logging 132');
                    // console.log(resp0.data);
                    self.$store.commit('userHasFakeEmail', resp0.data.has_fake_email);
                    resp = true;
                })
                    .catch(function (error) {
                        // console.log(error);
                        if (error.response.status === 401)//unauthorised, so the access token we tried to write in was wrong
                        {
                            let config = {
                                headers: {
                                    'content_type': 'application/x-www-form-urlencoded',
                                }
                            }
                            //unauthorised = access token wrong, so we should try and refresh
                            axios.post('/oauth/token', {
                                'grant_type': 'refresh_token',
                                'refresh_token': self.$store.getters.refreshToken,
                                'client_id': self.$store.getters.clientId,//
                                'client_secret': self.$store.getters.clientSecret,// security concerns
                            }, config).then(function (response) {
                                if (response.data.access_token) {
                                    let config2 = {
                                        headers: {
                                            'Authorization': 'Bearer ' + response.data.access_token,
                                        }
                                    }
                                    axios.get('api/user').then(function (resp2) {
                                        //we know our signed in user now works
                                        // console.log('Logging 162');
                                        // console.log(resp2.data);
                                        self.$store.commit('userHasFakeEmail', resp2.data.has_fake_email);
                                        resp = true;
                                        self.$store.commit('userAccessToken', response.data.access_token)
                                        self.$store.commit('userRefreshToken', response.data.refresh_token)

                                    }, config2).catch(function () {//still didn't work so we don't have a valid user
                                        resp = false;
                                    })
                                } else {
                                    resp = false;
                                }
                            });
                        } else {
                            // console.log(error.response);
                        }
                    });
                // console.log('about to return ' + resp);
                return resp;
            },
            submitEmail() {
                let self = this;
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + self.$store.getters.accessToken,
                    }
                }
                // unauthorised = access token wrong, so we should try and refresh
                axios.post('/api/user', {
                    'name': self.fakeemail.name,
                    'email':self.fakeemail.email,
                    'phone':self.fakeemail.phone,
                    'dob':self.fakeemail.dob,
                    'marketing_email':self.fakeemail.marketing_email,
                    'marketing_phone':self.fakeemail.marketing_phone,
                },config).then(function (response) {
                    let config = {
                        headers: {
                            'Authorization': 'Bearer ' + self.$store.getters.accessToken,
                        }
                    }
                    //we must try setting the user's access token  to what we were told first before we can fire this
                    axios.get('api/user', config).then(function (response2) {
                        //we know our signed in user now works
                        //Save our USER
                        self.$store.commit('storeUser', {
                            'name': response2.data.name,
                            'email': response2.data.email,
                            'access_token': response.data.access_token,
                            'refresh_token': response.data.refresh_token
                        });
                        self.$store.commit('userHasFakeEmail', response2.data.has_fake_email);
                        this.$bvModal.hide('my-modal')

                    });
                });
            },
        }
    }
</script>

<style lang="scss">
#app {
  font-family: Barlow, Roboto, Open Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.navbar-brand:hover{
    color: #7290FF !important;
}
  .navbar-lg{
      .home-link{
          font-size: 20px;
          color: #7290FF;
      }
      .navbar-logo{
          margin-right: 14px;
      }
      .sign-in{
          margin-right: 4px;
      }
      .login-text{
          vertical-align: bottom;
      }
      .nav-link{
          color: black !important;
      }
  }
  .wrapper-container{
      background-color: #F5F9FF;

  }
    .inner-container{

    }

    .modal-content{
        border-radius: 8px;
    }
    .top-label{
        font-size: 12px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
</style>
