<template>
  <div class="order-type-selection">
    <img src="@/assets/start_order.svg">
    <h4 v-if="venue && (venue.delivery_orders || venue.collection_orders)">How would you like to recieve your order?</h4>
    <h4 v-else>This venue is not currently accepting orders</h4>
    <div v-if="venue && venue.delivery_orders">
      <b-button class="large-order-button row"  @click="setOrderType(3)" v-if="dt">
        <img src="@/assets/delivery_service.svg"  :class="ismodal !== true ? 'notmodal' : ''">
        <p class="col-12">Delivery</p>
      </b-button>
      <b-button class="large-order-button row"  @click="setOrderType(3)" disabled v-else>
        <img src="@/assets/delivery_service.svg"  :class="ismodal !== true ? 'notmodal' : ''">
        <p class="col-12">Delivery Not Available</p>
      </b-button>
    </div>
    <div v-if="venue && venue.collection_orders">
      <b-button class="large-order-button row" @click="setOrderType(0)" v-if="ct">
        <img src="@/assets/click_collect_service.svg"  :class="ismodal !== true ? 'notmodal' : ''">
        <p class="col-12">Click and Collect</p>
      </b-button>
      <b-button class="large-order-button row"  @click="setOrderType(0)" disabled v-else>
        <img src="@/assets/click_collect_service.svg"  :class="ismodal !== true ? 'notmodal' : ''">
        <p class="col-12">Collection Not Available</p>
      </b-button>
    </div>
    <b-button class="large-order-button row" @click="isViewingMenu(true)" >
      <img src="@/assets/start_order.svg"  :class="ismodal !== true ? 'notmodal' : ''">
      <p class="col-12">View Menu</p>
    </b-button>
    <!--                        <b-button class="view-menu d-md-none" @click="changeService()">Change Service Type</b-button>-->
  </div>
</template>

<script>
    export default {
        name: "LgOrderTypeSelection",
        props:[
            'dt', 'ct', 'venue','order', 'ismodal'
        ],
        data() {
            return {

            }
        },
        mounted() {
            // console.log('about to log menucat');
            // console.log(this.category);
            // console.log('about to log menucat');
        },
        computed:{
          deliveryType(){
            switch(this.order.type)
            {
              case 0:
                return 'click & collect';
              case 3:
                return 'delivery';
              default:
                return '';
            }
          },
          changeServiceTitle()
          {
            if(this.order.type !== null)
            {
              return 'Change your order method';
            }
            else{
              return 'Select Service Type';
            }
          },
        },
        methods: {
          setOrderType(type)
          {
            if(this.venue.slug !== this.$store.getters.orderVenue)
            {
              this.$store.commit('clearOrder');
              this.$store.commit('setVenue',this.venue.slug);
            }
            //set or update the delivery type
            this.order.type = type;
            this.$store.commit('setOrderType',type);
            this.$toastr.s("SUCCESS", "Order type changed to " + this.deliveryType);
            this.$emit('hideOrderTypeModal');
          },
          isViewingMenu(val){
            if(val)
            {
              this.$emit('viewMenu');
            }
          }
        }
    }
</script>

<style scoped lang="scss">
.venue-information{
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  h1{
    padding-top: 4px;
    font-size: 34px;
    color: #070314;
    font-weight: 600;
    margin-bottom: 1px;
  };
  h4{
    color: #070314;
    font-size: 14px;
    font-weight: 700;
  };
  p{
    color: #070314;
    font-size: 14px;
    font-weight: 400;
    a{
      color: #7196FF;
      &:hover{
        color: darken(#7196FF,20%);
        cursor: pointer;
      }
    }
  }
}
.offer-item-text{
  font-size: 15px;
  font-weight: 600;
}

.greyline{
  border-bottom: solid #A3AFC0 1px;
}
.venue-open-times{
  font-size: 14px;
  font-weight: 400;
}
.mid-page-nav{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: #ffffff;
  height: 67px;
}
.bottom-section-container{
  min-height: 50px;
}


.menu-button{
  /*max-width: 90px;*/
  background-color: transparent;
  color: #7290FF;
  font-weight: bold;
  border: none;
}
.menu-button:active{
  background-color: #7290FF !important;
  font-weight: bold;
  color: white;
  border-radius: 16px;
}
.active-menu-category{
  background-color: #7290FF;
  font-weight: bold;
  color: white;
  border-radius: 16px;
}

.food-button-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.notmodal{
  left: 23% !important;
}


.view-menu{
  background: none;
  border: none;
  color: #7290FF;
  font-size: 14px;
  font-weight: 700;
}
.view-menu:hover{
  color: darken(#7196FF,20%);
}
.view-menu:active{
  color: darken(#7196FF,20%) !important;
  background: none !important;
  border: none !important;
}
.view-menu:focus{
  color: darken(#7196FF,20%) !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
.sub-btn{
  padding: 2px;
  font-size: 14px;
}
.plus-btn{
  padding: 2px;
  font-size: 14px;
}
.is-active-lob{
  background-color: #7196FF !important;
  color: white !important;
}
.can-be-selected{
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.venue-offer-item{
  margin-top: 15px;
  img{
    margin-right: 15px;
  }
}

.toggle {
  border-radius: 16px;
  width: 136px;
  height: 37px;
  background-color: #F5F9FF;
  padding: 3px;
  position: relative;
  display: flex;
  align-items: center;
}

.toggle-highlight {
  background-color: white;
  box-shadow: 0px 1px 2px #A3AFC0;
  border-radius: 16px;
  width: 67px;
  height: 30px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.toggle-inner {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100px;
  margin: auto;
  label{
    padding-left: 0;
    padding-right: 0;
  }
}

.toggle-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #A3AFC0;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}

.toggle-highlight {
  transition: transform 0.3s;
  transform: translateX(0);
}

.toggle-drinks {
  .toggle-drinks-text {
    color: #7290FF;
  }
}

.toggle-food {
  .toggle-food-text {
    color: #7290FF;
  }

  .toggle-highlight {
    transform: translateX(67px);
  }
}
.label-padding{
  padding-top: 15px;
}
.m-c-row-height{
  height: 67px;
}
@media(max-width: 450px)
{
  .large-order-button{
    img{
      left:  10% !important;
    }
  }
}

@media(max-width: 992px)
{
  .notmodal{
    left: 28% !important;
  }
}
@media(max-width: 768px)
{
  .notmodal{
    left: 28% !important;
  }
  .open-times-exception{
    margin-top: 15px !important;
  }
  .label-padding{
    margin-left: 10px;
  }
  .venue-offers{
    padding-bottom: 20px;
    margin-left: 8px;
  }
  .venue-information{
    margin-left: 10px;
    margin-top: 0;
    h1{
      font-size: 20px;
    }
    margin-right: 10px;
  }
  .agile{
    padding-left: 10px;
    padding-right: 10px;
  }

  .order-type-selection{
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    padding-bottom: 10px;
    margin-bottom: 100px;
    text-align: center;
    justify-content: center;
  }

  #whole-page{
    padding-bottom: 160px;
  }
  .food-button-wrapper{
    justify-content: left;
  }
}
@media(max-width: 630px)
{
  .notmodal{
    left: 22% !important;
  }
}

.order-type-selection{
  padding-left: 0;
  padding-right: 0;
  padding-top: 40px;
  padding-bottom: 10px;
  margin-bottom: 100px;
  text-align: center;
  justify-content: center;
}
.large-order-button{
  max-width: 350px;
}

.mobile-get-the-app-popup{
  z-index: 10302;
  background-color: white !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 33vh;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  touch-action: none;
}
.get-the-app-title{
  font-weight: 600;
  font-size: 20px;
  color: #070314;
  margin-bottom: 30px;
}
.mobile-greyscale{
  background: black;
  opacity: 0.4;
  height: 100vh;
  touch-action: none;
  width: 100%;
  z-index: 10301;
}
.gtap-row{
  margin-left: 10px;
  margin-right: 10px;
}
.asset-logo{
  height: 42px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 42px;
}
.app-text{
  font-weight: 700;
  font-size: 14px;
  vertical-align: top;
  padding-top: 10px
}
.hori-row{
  margin-top: 20px;
}
.gtap-browser-btn{
  width: 100%;
  border-radius: 8px;
  background: white;
  color: black;
  border: solid 1px #CBD7E8;
}
.gtap-app-btn{
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(270deg,#71C1FF,#6F47FF);
  border: none;
}
.menu-dropdown {
  width: 150px;
  border-radius: 16px;
  background: #7290FF;
}

</style>