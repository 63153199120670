import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/sass/custom.scss'
import App from "./App";
import router from './router'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls";
import VueToastr from "vue-toastr";
var ls = new SecureLS({ isCompression: false });
// import Lingallery from 'lingallery'
import VueAgile from 'vue-agile'
import isToday from 'dayjs/plugin/isToday'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';

dayjs.extend(isToday);
dayjs.extend(customParseFormat);
Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs
    }
  }
});

Vue.use(VueAgile)
Vue.use(VueToastr);
Vue.use(Vuex)
Vue.use(VueCookies)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Vue.component('lingallery',Lingallery);
Vue.config.productionTip = false
const store = new Vuex.Store({
  state: {
    currently_signed_in: null,
    user: {
      name: null,
      access_token: null,
      refresh_token: null,
      email: null,
      has_fake_email: null
    },
    order:{
      venue: null,
      items: [

      ],
      type: null
    },
    globalError: null,
    login_redirect: null,
    postcode: null
  },
  getters: {
    stripeKey: () => {
      return process.env.VUE_APP_PUBLISHABLE_KEY
    },
    userHasFakeEmail: state => {
      return state.user.has_fake_email;
    },
    login_redirect: state => {
      return state.login_redirect;
    },
    userSignedIn: state => {
      return state.currently_signed_in;
    },
    accessToken: state => {
      return state.user.access_token;
    },
    refreshToken: state => {
      return state.user.refresh_token;
    },
    name: state => {
      return state.user.name;
    },
    clientId: () => {
      return process.env.VUE_APP_CLIENT_ID
    },
    clientSecret: () => {
      return process.env.VUE_APP_CLIENT_SECRET
    },
    localGlobalHome: () => {
      return process.env.VUE_APP_LOCAL_GLOBAL_WEBSITE_URL
    },
    user: state => {
      return state.user;
    },
    order: state => {
      return state.order;
    },
    orderVenue: state => {
      return state.order.venue;
    },
    globalError: state => {
      return state.globalError;
    },
    deliveryPostcode: state => {
      return state.postcode;
    },
  },
  mutations: {
    orderCompleted(state)
    {
      state.order = {
        venue: null,
        items: [],
        type: null
      }
    },
    storeUser(state,payload)
    {
      state.user.name = payload.name;
      state.user.access_token = payload.access_token;
      state.user.refresh_token = payload.refresh_token;
      state.user.email = payload.email;
    },
    userSignedIn(state,payload){
      state.currently_signed_in = payload;
    },
    userAccessToken(state,payload){
      state.user.access_token = payload;
    },
    userRefreshToken(state,payload){
      state.user.refresh_token = payload;
    },
    userHasFakeEmail(state,payload){
      state.user.has_fake_email = payload;
    },
    setFullOrder(state,payload){
      state.order = payload;
    },
    clearOrder(state){
      state.order = {
        venue: null,
        items: [],
        type: null
      }
    },
    setVenue(state,payload){
      state.order.venue = payload;
    },
    setOrderType(state,payload){
      state.order.type = payload;
    },
    setGlobalError(state,payload){
      state.globalError = payload;
    },
    setLoginRedirect(state,payload){
      state.login_redirect = payload;
    },
    deliveryPostcode(state,payload){
      state.postcode = payload;
    }
  },
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
})

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common = {
  'Accept': 'application/json',
  'Device-Type': 'web',
  'Device-Version': 999,
  'App-Name': 'customer-app'
}
// axios.defaults.headers.common = {'Device-Type:': `ios`}
// axios.defaults.headers.common = {'Device-Version:': `999`}
export default axios;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


