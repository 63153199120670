import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Venue from "../views/Venue";
import Orders from "@/views/Orders";
import Order from "@/views/Order";
import Checkout from "../views/Checkout";
import MobileBasket from "@/views/MobileBasket";
import Profile from "@/views/Profile";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user/order-history',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/user',
    name: 'User Profile',
    component: Profile
  },
  {
    path: '/v/:venueslug/order/:order',
    name: 'Order',
    component: Order
  },
  // {
  //   path: '/user',
  //   name: 'Profile',
  //   component: Profile
  // },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'sign-in',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Reset.vue')
  },
  {
    path: '/sign-up',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/sign-in-apple',
    name: 'sign-in-apple',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/sign-in-facebook',
    name: 'sign-in-facebook',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/sign-in-google',
    name: 'sign-in-google',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/v/:venueslug',
    name: 'Venue',
    component: Venue
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/mobile-basket',
    name: 'Basket',
    component: MobileBasket
  },
  {
    path: '/404',
    name: 'Not Found',
    component: () => import( '../views/404.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import( '../views/Success.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
