<template>
  <div id="whole-page">
    <back-to-venue-button></back-to-venue-button>
    <b-container fluid="md" class="inner-container" id="header-container">
      <!--    {{ $route.params.venueslug }}-->
      <div class="profile-container">
        <b-row>
          <b-col cols="12" md="6" class="row">
            <div class="form-group row col-12 text-left mobile-class-left-0">
              <div class="col-6">
                <label class="col-12 pl-0 col-form-label text-md">Order Service</label>
                <input type="text" class="form-control" :value="orderType" disabled>
              </div>
              <div class="col-6">
                <label class="col-12 pl-0 col-form-label text-md">Ref. Order</label>
                <input type="text" class="form-control" :value="refOrder" disabled>
              </div>
              <div class="col-6">
                <label class="col-12 pl-0 col-form-label text-md">Your name</label>
                <input type="text" class="form-control" :value="order['food-order'] != null ? order['food-order']['name'] : user.name" disabled>
              </div>
              <div class="col-6">
                <label class="col-12 pl-0 col-form-label text-md">Contact number </label>
                <input type="text" class="form-control" :value="contactNumber" disabled>
              </div>
              <div class="col-6">
                <label class="col-12 pl-0 col-form-label text-md">Time of order</label>
                <input type="text" class="form-control" :value="orderTime" disabled>
              </div>
              <div class="col-6">
                <label class="col-12 pl-0 col-form-label text-md">Date of order</label>
                <input type="text" class="form-control" :value="orderDate" disabled>
              </div>
              <div class="col-12">
                <label class="col-12 pl-0 col-form-label text-md">Comments</label>
                <textarea class="form-control" disabled v-model="comments"/>

              </div>
            </div>
          </b-col>
          <b-col cols="12" offset-md="1" md="5" class="row rhs mobile-class-left-0">

            <!-- BOOKING -->
            <div class="order-reciept-card" v-if="order.booking != null">
              <img class="top-img" :src="orderImage">
              <div class="order-panel-container">
                <b-row class="top-heading">
                  <b-col cols="12">
                    <h4>Your Order at <a class="blue-text" :href="/v/ + orderVenueSlug">{{orderVenueName}}</a></h4>
                  </b-col>
                </b-row>
                <b-row class="product-rows" v-if="order.booking['pre-order'] != null">
                  <b-col class="col-12 row" v-for="itm in order.booking['pre-order']" :key="itm.id">
                    <b-col class="btn-col" cols="3">
                      {{itm.quantity}}x
                    </b-col>
                    <b-col cols="5" class="name-col">{{itm.name}}</b-col>
                    <b-col cols="4" class="price-col">£{{(itm.price_paid_per_item/100 * itm.quantity).toFixed(2)}}</b-col>
                    <b-col cols="12" class="row" v-if="itm.combined_products && itm.combined_products.length > 0">
                      <b-col offset="3" cols="8" class="combined-products-col">+ {{itm.combined_products[0].name }}&nbsp;&nbsp;</b-col>
                    </b-col>
                    <b-col offset="1" cols="11" class="fullwidthline"></b-col>
                  </b-col>
                </b-row>
                <b-row class="totals-row">
                  <b-col class="col-12 row">
                    <b-col cols="6">
                      Minimum Spend
                    </b-col>
                    <b-col offset="2" cols="4" class="st-price-col">£{{ (order.booking.minmum_spend / 100).toFixed(2)}}</b-col>
                  </b-col>
                  <b-col class="col-12 row" v-if="order['fees'] != 0">
                    <b-col cols="6">
                      Fees
                    </b-col>
                    <b-col offset="2" cols="4" class="st-price-col">£{{ (order['fees']/100).toFixed(2)}}</b-col>
                  </b-col>
                  <b-col class="col-12 row mt-2">
                    <b-col cols="6">
                      Total
                    </b-col>
                    <b-col offset="2" cols="4" class="tot-price-col">£{{ (order.total_price / 100).toFixed(2)}}</b-col>
                  </b-col>
                </b-row>

                <lg-order-cancellation
                  :order="order"

                  @order-cancelled="onOrderCancelled"
                ></lg-order-cancellation>
              </div>
            </div>


            <!-- FOOD ORDER -->
            <div class="order-reciept-card" v-else>
              <img class="top-graphic" :src="orderImage">
              <div class="order-panel-container">
                <b-row class="top-heading">
                  <b-col cols="12">
                    <h4>Your Order at <a class="blue-text" :href="/v/ + orderVenueSlug">{{orderVenueName}}</a></h4>
                  </b-col>
                </b-row>
                <b-row class="product-rows">
                  <b-col class="col-12 row" v-for="itm in food_order_items" :key="itm.id">
                    <b-col class="btn-col" cols="3">
                      {{itm.quantity}}x
                    </b-col>
                    <b-col cols="5" class="name-col">{{itm.name}}</b-col>
                    <b-col cols="4" class="price-col">£{{(itm.price_paid_per_item/100 * itm.quantity).toFixed(2)}}</b-col>
                    <b-col cols="12" class="row" v-if="itm.combined_products && itm.combined_products.length > 0">
                      <b-col offset="3" cols="8" class="combined-products-col">+ {{itm.combined_products[0].name }}&nbsp;&nbsp;</b-col>
                    </b-col>
                    <b-col offset="1" cols="11" class="fullwidthline"></b-col>
                  </b-col>
                </b-row>
                <b-row class="totals-row">
                  <b-col class="col-12 row" v-if="order['food-order'] != null && order['food-order']['order_type_text'] === 'Delivery'">
                    <b-col cols="6">
                      Delivery Charge
                    </b-col>
                    <b-col offset="2" cols="4" class="st-price-col">{{order['food-order']['delivery']['charge']}}</b-col>
                  </b-col>
                  <b-col class="col-12 row" v-if="order['fees'] != 0">
                    <b-col cols="6">
                      Fees
                    </b-col>
                    <b-col offset="2" cols="4" class="st-price-col">£{{ (order['fees']/100).toFixed(2)}}</b-col>
                  </b-col>
                  <b-col class="col-12 row mt-2">
                    <b-col cols="6">
                      Subtotal
                    </b-col>
                    <b-col offset="2" cols="4" class="tot-price-col">£{{ (order.total_price / 100).toFixed(2)}}</b-col>
                  </b-col>
                </b-row>

                <b-row>
                  <!-- Show Refund Policy -->
                  <b-col class="col-12 mt-2">
                    <b-button
                        variant="secondary"
                        size="sm"

                        class="m-0 w-100"

                        v-b-modal.refundPolicyModal

                        v-if="order['food-order'].refund_policy"
                    >
                      Request Refund
                    </b-button>
                    <b-modal id="refundPolicyModal"  title="Refund Details">
                      <p class="my-4">{{order['food-order'].refund_policy}}</p>
                      <template #modal-footer>
                        <div class="w-100">
                          <b-button
                              variant="dark"
                              size="sm"

                              class="float-right"

                              @click="$bvModal.hide('refundPolicyModal')"
                          >
                            Close
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from "@/main";
import BackToVenueButton from "@/components/BackToVenueButton";
import LgOrderCancellation from "../components/LgOrderCancellation";

export default {
  name: 'Order',
  props: {},
  components: {LgOrderCancellation, BackToVenueButton},
  data() {
    return {
      order: {},
      order_id: null,
      user: null,
      food_order_items: {}
    }
  },
  mounted: function () {
    this.order_id = this.$route.params.id
    let self = this;
    let config = {
      headers: {
        'Authorization': 'Bearer ' + self.$store.getters.accessToken,
      }
    }
    if (this.order != null) {
      axios.get('api/user', config).then(function (response2) {
        self.user = response2.data;
      }).catch(function () {
        self.$router.push('/login');
      }).then(function () {
        self.getOrder();
      });
    }
  },
  methods: {
    onOrderCancelled(){
      if(this.order.booking != null){
        this.order.booking.can_cancel = false;
      }

      this.$router.back();
    },
    getOrder(){
      axios.get('api/customer/user/orders?order_id=' + this.$route.params.order, {
        headers: {
          'Authorization': 'Bearer ' + this.$store.getters.accessToken,
        }
      }).then((orderResponse) => {
        this.order = orderResponse.data[0];
        this.food_order_items = orderResponse.data[0]['food-order'] != null ? orderResponse.data[0]['food-order']['items'] : {};
      }).catch(() => {
        this.$toastr.e('ERROR', 'Order doesn\'t belong to user');
        this.$router.back();
      }).then(() => {
        //does anything need to happen here?
      });
    }
  },
  computed: {
    orderVenueName(){
      if (this.order.booking != null && this.order.booking.tables[0] != null) {
        return this.order.booking.venue.name;
      }
      else{
        return this.order['food-order']['venue-name'];
      }
    },
    orderVenueSlug(){
      if (this.order.booking != null && this.order.booking.tables[0] != null) {
        return this.order.booking.venue.slug;
      }
      else{
        return this.order['food-order']['venue-slug'];
      }
    },

    orderImage() {
      if (this.order.booking != null  && this.order.booking.tables[0] != null) {
        //then it's a table order
        return this.order.booking.tables[0].image;
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Delivery') {
        return 'https://clubglobal-2020.s3-eu-west-1.amazonaws.com/delivery_service.svg'
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Collection') {
        return 'https://clubglobal-2020.s3-eu-west-1.amazonaws.com/click_collect_service.svg'
      } else {
        return 'https://clubglobal-2020.s3-eu-west-1.amazonaws.com/table_service.svg'
      }
    },
    contactNumber() {
      if (this.order.booking != null && this.order.booking.tables[0] != null) {
        //then it's a table order
        return this.user.phone ?? null;
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Delivery') {
        return this.order['food-order']['delivery']['phone'] ?? null
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Collection') {
        return this.order['food-order']['collection']['phone'] ?? null
      } else {
        return this.user.phone ?? null;
      }
    },
    orderType() {
      if (this.order.booking != null && this.order.booking.tables[0] != null) {
        //then it's a table order
        return 'Table Booking'
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Delivery') {
        return 'Delivery'
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Collection') {
        return 'Click and Collect'
      } else {
        return 'Table Service'
      }
    },
    orderTime() {
      if (this.order.booking != null && this.order.booking.tables[0] != null) {
        //then it's a table order
        console.log('came here');
        return this.$date(this.order.booking.tables[0].time,'HH:mm:ss').format('HH:mm')
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Delivery') {
        return this.order['food-order']['delivery']['time']
      } else if (this.order['food-order'] != null && this.order['food-order']['order_type_text'] === 'Collection') {
        return this.order['food-order']['collection']['time']
      } else {
        return this.$date.unix(this.order['created_at']).format('HH:ss')
      }
    },
    orderDate() {
      if (this.order.booking != null && this.order.booking.tables[0] != null) {
        //then it's a table order
         return this.$date(this.order.booking.date, "YYYY-MM-DD").format('DD MMM YYYY');
      }
      else{
       return this.$date.unix(this.order['created_at']).format('DD MMM YYYY')
      }
    },
    comments(){
      if(this.order['food-order'])
      {
        return this.order['food-order']['comments'];
      }
      else return null;
    },
    refOrder(){
      return '#'+this.order.uuid.slice(-6).toUpperCase();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.rhs{
  text-align: left;
}
.profile-container{
  padding-top: 33px;
  h4{
    font-size: 20px;
    font-weight: 600;
    color: #7290FF;
    padding-top: 13px;
  }
  h5{
    font-weight: 700;
    font-size: 14px;
    color:#A3AFC0
  }
  h3{
    font-size: 20px;
    font-weight: 600;
    color: #070314;
    margin-top: 10px;
  }
  h2{
    font-size: 28px;
    font-weight: 400;
    color: #070314;
    margin-top: 10px;
    margin-bottom: 0;
  }
  input{
    border-radius: 8px;
  }
  select{
    border-radius: 8px;
  }
  label{
    font-size: 14px;
  }
  textarea{
    border-radius: 8px;
  }
}
.user-info-box{
  border-radius: 16px;
  background: white;
  border: 1px solid #CBD7E8;
  padding: 33px;
}
.info{
  color: #7290FF;
  font-size: 14px;
  font-weight: 400;
  vertical-align: top;
}
.upcoming-order{
  background: white;
  border-radius: 16px;
  margin-bottom: 40px;
  margin-top: 10px;
  overflow: hidden;
}

.u-o-logo{
  max-height: 100px;
  padding: 15px;
}
.u-o-info{
  margin-bottom: 12px;
  h1{
    font-size: 20px;
    font-weight: 600;
    color: #070314;
  }
  h3{
    font-size: 14px;
    font-weight: 700;
    color: #070314;
  }
}
.btn-voi{
  background: linear-gradient(270deg,#71C1FF,#6F47FF);
  color: #ffffff;
  border-radius: 8px;
  border: 0;
  padding: 10px 16px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
}
.smalltoptext{
  margin-top: 16px;
  font-size: 10px;
  color: #A3AFC0;
  font-weight: 600;
}
.maintext{
  font-size: 14px;
  font-weight: 400;
  color: #070314;
}
.middle-row{
  margin-bottom: 24px;
}
.non-venue-img{
  padding: 10px;
  background:linear-gradient(270deg,#F0ECFF,#D0EAFF);
}

.top-img{
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  max-height: 285px;
  width: 100%;
}
.top-graphic{
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  padding: 18px !important;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.inc-btn{
  border-radius: 100%;
  max-width: 15px;
  width: 15px;
  height: 15px;
  padding: 0;
  max-height: 15px;
  font-size: 14px;
  padding-bottom: 1px;
  line-height: 7px;
  margin-bottom: 3px;
  background-color: #A3AFC0;
  border-color: #A3AFC0;
}
.sub-btn{
  margin-right: 4px;
}
.plus-btn{
  margin-left: 4px;
}

.order-panel-container{
  h4{
    font-weight: 600;
    font-size: 20px;
    color: #070314;
  }
  .top-heading{
    padding-bottom: 20px;
  }
  background: white;
  border-radius: 0 0 16px 16px;
  padding: 34px;
}
.reset-link{
  margin-right: 40px;
}
.name-col{
  text-align: left !important;
}
.price-col{
  font-weight: 700;
  color: #070314;
  text-align: right !important;
}
.st-price-col{
  font-weight: 700;
  text-align: right !important;
  color: #070314;
}
.tot-price-col{
  font-weight: 700;
  color: #070314;
  font-size: 20px;
  text-align: right !important;
}
.fullwidthline{
  border-bottom: 1px solid #A3AFC0;
  margin-bottom: 12px;
}
.fullwidthline{
  margin-left: 22px;
  margin-top: 6px;
  color: #A3AFC0;
  opacity: 30%;
}
.btn-col{
  font-weight: 700;
}
.totals-row{
  margin-top: 25px;
  text-align: left !important;
}
.order-button{
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(270deg,#71C1FF,#6F47FF);
  border: none;
  margin-top: 25px;
}
.order-reciept-card{
  box-shadow: 0 10px 20px 0 rgba(163, 175, 192, 0.25);
}
@media(max-width: 768px){
  .order-reciept-card{
    width: 100%;
  }
  .mobile-class-left-0{
    margin-left: 0 !important;
  }
}
.blue-text{
  font-weight: 600;
  color: #7290FF;
}
</style>
